import React, { Component, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import { Form, FormControl } from "react-bootstrap";
import Footer from '../components/footer';
import CanvasInfo2 from '../components/CanvasInfo2';
import CollectiblesCollections from '../components/collectiblesCollections';
import Community from '../components/community';
import WhatNext from '../components/whatNetxt';

import { Tabs, Tab } from "react-bootstrap";
import { Link } from '@reach/router';

import iInstagram from "../img/iconmonstr-instagram-11.svg";
import iTwitter from "../img/iconmonstr-twitter-3.svg";
import iLink from "../img/iconmonstr-link-1.svg";
import DeleteButton from '../components/deleteButton';

import { useForm } from 'react-hook-form';




const EditProfile = ({ prevStep }) => {

  const back = e => {
    e.preventDefault();
    prevStep();
  };

  const { register, handleSubmit, formState: { errors } } = useForm({
    roles: {
      defaultValue: {
      }
    }

  });
  const onSubmit = data => console.log(data);
  console.log(errors);

  return (
    <div>
      <div className="jumbotron no-bg" style={{ padding: "0px" }}>
        <div className="canvas">
          <img src="https://uc3cb29c7b19f85458f01510640a.previews.dropboxusercontent.com/p/thumb/ABWXSSfPRYDzR5NEGWr8ZBUyoAIJPN8wDRCNZ5dqPDzs9rHTJ72arXLpMxVgiTNRvy4KX7JugnyAoOLrC-l-bk4q6gGoGBzGnkcCaocmXy4loF74CdgZoKXcfP5jAfEAm5xLY4h84itlSXitubnrcAOcNu-1ggYSQpujdDgde5S52FBD15-DWBOE87yc22wys7z2PMIjJcO0Tw0KTB4I2NHuc0QWuKLl8C3lXqNF_onw4ipZQ9gDIXdzOLYYb_6unBkcWIXER8oRmA4TkOj2g6XPUHLt_V-vUX7u90YQH6s8w6JPOzzvHXM36g5pbVQs546uWCWaLVfASTQhUkVYfiIVWApJvhhR3MccDlheiYNvJOJTqQnPj40ck2xqEECMSqU/p.png" alt="" id="img" className="img" style={{ background: "whiteSmoke", width: "100%", height: "100%" }} />
          <CanvasInfo2 />
        </div>
      </div>

      <section className='container no-bottom user-profile-container' >

        <form onSubmit={handleSubmit(onSubmit)} className="row" style={{ justifyContent: "center" }} >
          <div className="col-md-5 col-sm-6 col-xs-1" >

            <div className=" icons-img-container" style={{ marginTop: "4.5rem" }}>
              <div style={{ marginBottom: "1rem" }}>
                <InputGroup size="sm" className="rrss-input-container">
                  <input type="text" placeholder="@artisTwitter  " {...register("@artisTwitter", { required: true, maxLength: 25 })} />
                  <InputGroup.Prepend>
                    <img style={{ width: "15px", height: "15px", marginLeft: "0.5rem" }} src={iTwitter} />
                  </InputGroup.Prepend>
                </InputGroup>
                <InputGroup size="sm" className="rrss-input-container" >
                  <input id="rrss-input" type="text" placeholder="@artistInstagram  " {...register("@artistInstagram", { required: true, maxLength: 24 })} />
                  <InputGroup.Prepend>
                    <img style={{ width: "15px", height: "15px", marginLeft: "0.5rem" }} src={iInstagram} />
                  </InputGroup.Prepend>
                </InputGroup>
                <InputGroup size="sm" className="rrss-input-container">
                  <input type="url" placeholder="www.artistWeb.com  " {...register("artisWeb", { required: true })} />
                  <InputGroup.Prepend>
                    <img style={{ width: "15px", height: "15px", marginLeft: "0.5rem" }} src={iLink} />
                  </InputGroup.Prepend>
                </InputGroup>
              </div>
              <div className="img-profile-container" >
                <img className="img-profile" src="https://www.xatobaxestion.com/wp-content/uploads/2016/09/default-user-img.jpg" alt="" />
              </div>
            </div>

            <div className="col-md-12 col-sm-6 col-xs-1 edit-btn-container" >
              <button className="btn-experience4" onClick={back} style={{ background: "black", color: "white", marginRight: "1rem" }} >Cancel</button>
              <button type="submit" className="btn-experience5" style={{ color: "white", marginRight: "1rem" }} >Save</button>
              {/* <input type="submit" name=" Save" className="btn-experience5" /> */}
            </div>

            <div className="col-md-12 col-sm-6 col-xs-1 user-container" style={{ marginTop: "1.5rem" }}>
              <p>Address </p>
              <p>User stats</p>
              <hr className="hr-prof" />
              <ul>
                <li>22 artworks </li>
                <li>240 visits</li>
                <li>340 favorites</li>
                <li>10 sales</li>
                <li>Total incomes: 1049 MATIC</li>
              </ul>
            </div>
          </div>

          <div className="col-md-1 col-sm-6 col-xs-1"></div>

          <div className="col-md-5 col-sm-6 col-xs-1" style={{ textAlign: "left" }}>
            <InputGroup size="lg" className="mb-3" style={{ marginTop: "5rem" }}>
              <input type="text" placeholder="  ArtistName" {...register("Artist Name", { required: true, maxLength: 25 })} style={{ textAlign: "left", height: "2.3rem" }} />
              <InputGroup.Prepend></InputGroup.Prepend>
            </InputGroup>

            <div>
              {['checkbox'].map((index) => (
                <fieldset
                  key={`inline-${index}`}
                  className="mb-3"
                  style={{ color: "black" }}
                >
                  <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref" value="roles" > Role: </Form.Label>
                  <Form.Check inline label="artist" name="Artist" type={index} id={`inline-${index}-1`} value="artist" {...register("roles", { required: true })} />
                  <Form.Check inline label="curator" name="Curator" type={index} id={`inline-${index}-2`} value="curator"  {...register("roles", { required: true })} />
                </fieldset>
              ))}
            </div>

            <hr className="hr-effects2" style={{ height: "0.3rem" }} />
            <InputGroup size="sm" className="mb-2">
              <input type="text" placeholder="  Email" {...register("Email", { required: true, pattern: /^\S+@\S+$/i })} style={{ textAlign: "left", marginTop: "1rem" }} />
              <InputGroup.Prepend></InputGroup.Prepend>
            </InputGroup>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Control as="textarea" rows={3}  {...register("Bio", { required: true, maxLength: 800 })}  />
              {/* <p id="contador" style={{ textAlign: "right", opacity: "0.3" }}>
                0/800
              </p> */}

            </Form.Group>
          </div>
        </form>
      </section>
      {/*---------------------------------ARTWORK----------------------------------------------------------------- */}
      <div className='collectible-container container no-top no-bottom'  >
        <div className='row'>
          <div className="col-md-12 col-sm-6 col-xs-1" style={{ textAlign: "center", marginTop: "2rem" }}>
            <hr className="hr-effects3" style={{ height: "0.3rem" }} />
          </div>
          <div className="spacer-double"></div>
          <div className='col-lg-12 ' style={{ textAlign: "center", marginBottom: "2rem" }}>
            <h2>Artworks</h2>
          </div>
        </div>
        <Tabs fill defaultActiveKey="home">

          <Tab eventKey="home" title="In Experiences">
            <hr className="vertical-line" />
            <div>
              <CollectiblesCollections />
            </div>
          </Tab>
          <Tab eventKey="profile" title="In collections">
            <hr className="vertical-line1" />
            <div>
              <CollectiblesCollections />
            </div>
          </Tab>
          <Tab eventKey="contact" title="Collected">
            <hr className="vertical-line2" />
            <div>
              <CollectiblesCollections />
            </div>
          </Tab>
          <Tab eventKey="Facv" title="Favorites">
            <hr className="vertical-line3" />
            <div>
              <CollectiblesCollections />
            </div>
          </Tab>
        </Tabs>

      </div>




      <section className='hdm-btns' style={{ background: "#EAEAEA", marginTop: "" }}>
        <DeleteButton />
        <div className='row '>
          <div className="col-md-12 col-sm-6 col-xs-1 container" style={{ textAlign: "center", marginTop: "" }}>
            <hr className="hr-effects3" style={{ height: "0.3rem" }} />
          </div>
          <div className="spacer-double  container"></div>
          <div className='col-lg-12  ' style={{ textAlign: "center" }}>
            <h2>Harddiskmuseum</h2>
          </div>

          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Become a Harddiskmuseum artist</button>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Visit the Museum</button>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Our blog</button>
          </div>
        </div>
      </section>

      <section className="row" style={{ textAlign: "center" }}>
        <div className="col-md-12 col-sm-1 col-xs-1" >
          <Community />
        </div>
      </section>

      <Footer />
    </div>

  );
}
// }


export default EditProfile;


