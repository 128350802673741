import React, { Suspense } from "react";
import ThreeScene from "../ThreeScene/ThreeScene";
import { Link } from "@reach/router";
import LOGOC from "../img/721_logo_color.svg";

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? "active" : "non-active",
      };
    }}
  />
);

const NbExperience = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        margin: "0",
        padding: "0",
        position: "absolute",
      }}
    >
      <div className="logo-container-full" style={{}}>
        <NavLink to="/">
          <img
            src={LOGOC}
            alt="Imagen PNG alternativa"
            style={{
              width: "4rem",
              height: "4rem",
              marginTop: "2rem",
              marginLeft: "2rem",
            }}
          />
        </NavLink>
      </div>

      <Suspense fallback="rendering">
        <ThreeScene zoom="true"/>
      </Suspense>
    </div>
  );
};

export default NbExperience;
/*
background: #ffff0026;
    position: absolute;
    z-index: 55; */
