import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DeleteButton = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="text-container-colecciones" style={{borderRadius:"2rem"}} >

            <button className=" btn-h-r" onClick={handleShow} >Delete Account</button>

            <Modal show={show} onHide={handleClose}>

                <div style={{ textAlign: "center", marginTop: "2rem" }}><h3>Delete Account</h3></div>

                <Modal.Body style={{ textAlign: "center" }}><p>Are you sure you want to delete your account? This will permanently erase your account</p></Modal.Body>
                <div  style={{ textAlign: "center", marginBottom:"2rem" }}>
                    <hr className="hr-effectsmodal" style={{ height: "0.2rem", margin: "2rem" }} />
                    <button className="cancel-btn" variant="secondary" onClick={handleClose} style={{marginRight:"0.5rem"}}>
                        Cancel
                    </button>

                    <button className="delete-btn" variant="primary" onClick={handleClose} style={{marginLeft:"0.5rem"}} >
                        Delete account
                    </button>
                </div>
            </Modal>
        </div>
    );
}
export default DeleteButton;