import React, { Suspense, useEffect } from "react";
import { Link } from "@reach/router";

import Footer from "../components/footer";
import ThreeScene from "../../components/ThreeScene/ThreeScene.js";
import CanvasInfo from "../components/CanvasInfo";
import Community from "../components/community";
import Header from "../menu/header.js";
import Countdown from "../components/countdown";
import ReactGa from "react-ga";
import videoLogo from "../../assets/videoLogo721tres.mp4";


const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? "active" : "non-active",
      };
    }}
  />
);

const Homeone = () => {
  useEffect(() => {
    ReactGa.initialize(process.env.REACT_APP_GA_ID);
    ReactGa.pageview("/home");
  }, []);

  return (
    <>
      <div>
        <Header /> {/*NAVBAR */}
        <section className="jumbotron no-bg" style={{ padding: "0px" }}>
          <div className="canvas">
            {/* <Suspense fallback={'rendering'}>
              <ThreeScene />
            </Suspense> */}
            {/* <video muted loop id="myVideo">
              <source src={videoLogo} type="video/mp4" />
            </video> */}
            <div className="vid">
              <video
                loop
                autoPlay
                muted
                playsInline
                style={{
                  width: "100%",
                  left: "50%",
                  top: "50%",
                  height: "100%",
                  objectFit: "cover",
                }}
              >
                <source src={videoLogo} type="video/mp4" />
              </video>
            </div>

            <CanvasInfo />
            <Countdown />
          </div>
        </section>
        <section className="container no-bottom count-home">
          <div className="row" style={{ justifyContent: "center" }}>
            <div
              className="col-md-6 col-sm-12 col-xs-1"
              style={{ textAlign: "right" }}
            >
              <h3 className="h3-curatd-concept1 h3-c-concept">
                Our Curated concept
              </h3>
              <hr className="hr-effects" style={{ height: "0.3rem" }} />
              <p>
                <strong>ERC721</strong> corresponds to the acronym of a development standard on the blockchain that has revolutionised the way we understand a digital file.
                <br />
                In 2013 the artist <strong>Solimán Lopez</strong>, haunted by
                this obsession to treat digital files as unique objects, founded
                the <strong>Harddiskmuseum</strong>, a Museum of files in a hard
                drive of 2TB.
                <br />
                This is the <strong>Museum’s cryptographic folder</strong> and a
                new environment that complements the evolution of the project.
                <br />
                <br />
                This folder is organised through curated{" "}
                <strong>Decentralised Exhibitions</strong>,{" "}
                <strong>“Harddiskmuseum Collections”</strong> and the files of
                the <strong>Museum´s Artists</strong> and guest artists.
              </p>
            </div>
            {/* <div className="col-md-1 col-sm-12 col-xs-1"></div> */}

            <div
              className="col-md-6 col-sm-12 col-xs-1"
              style={{ textAlign: "left" }}
            >
              <h3 className="h3-curatd-concept2">Our Content</h3>
              <hr className="hr-effects2" style={{ height: "0.3rem" }} />
              <p>
                The <strong>721 Decentralised Exhibitions</strong> are based on
                3D environments, augmented and virtual realities and are
                available for being installed in other other Festivals, Museums,
                Galleries and Art Center platforms.
                <br />
                These experiences, aims to gradually builds the future{" "}
                <strong>MetaDiskMuseum.</strong>
                <br />
                <br />
                <strong>“Harddiskmuseum Collections”</strong> are curated
                packages of art by different artists that include an
                auto generated collectible image that makes each collection
                unique.
                <br />
                <br />
                Moreover we showcase individual artworks created by{" "} the artists which form part of the Harddiskmuseum’s Artists community. 
              </p>
            </div>
          </div>
        </section>
        <div
          id="page"
          className="col-md-12 col-sm-12 col-xs-1"
          style={{ textAlign: "center", padding: "0" }}
        >
          <marquee scrollamount="15" scrolldelay="2">
            <h2 className="we-curate animate-charcter">
              We respect the creation of our current time and the digital files
              behind.
            </h2>
          </marquee>
        </div>
        <div className="collectible-container container no-top no-bottom">
          <div className="row">
            <div
              className="col-md-12 col-sm-6 col-xs-1"
              style={{ textAlign: "center" }}
            >
              <hr
                className="hr-effects3"
                style={{ height: "0.3rem", marginBottom: "1rem" }}
              />
            </div>

            <div className="col-lg-12 " style={{ textAlign: "center", marginBottom: "20px" }}>
              <h2>BE PART OF OUR NEXT EXPERIENCE</h2>
            </div>
            <div className="spacer-simple"></div>
          </div>
          <div>
            <div
              style={{
                border: "1px solid black",
                height: "500px",
                width: "100%",
              }}
            >
              <Suspense fallback={"rendering"}>
                <ThreeScene zoom="false"/>
              </Suspense>
              <div
                className="nb-experience-btn"
              
              >
                <NavLink
                  className=""
                  to="/nb-experience"
                  style={{
                    marginTop: "1.5",
                    // marginBottom: "1rem",
                    textDecoration: "none",
                  }}
                >
                  <button className="btn-hdm-border">
                    Microverse Experience
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="text-container-colecciones">
          <p className="p-exhibition" style={{ fontSize: "larger" }}>
            OUR NEXT EXHIBITION "NON BINARY" WILL COUNT THE PRESENCE OF 10
            INTERNATIONAL ARTIST
            <br />
            5 ALREADY HARDDISKMUSEUM ARTIST
            <br />
            3 NEW GUESS ARTISTS
            <br />
            <strong>2 SELECTED AWARDED ARTISTS</strong>
          </p>
        </div>
        <div
          className="row text-container-colecciones"
          style={{ background: "#EFEFFD", textAlign: "center" }}
        >
          <div className="col-md-12 col-sm-6 col-xs-1 be-part-container">
            <div
              className="bepart"
    
            >
              <a href="https://harddiskmuseum.com/artist-apply/" target="_blank" rel="noreferrer">
                <button
                  className="btn-hdm-degraded"
                >
                  Participate!
                </button>
              </a>
            </div>
          </div>
          <div className="contact-container">
            <div className="col-md-6 col-sm-12 col-xs-1 if-you-text-container">
              <h3 className="if-you-text">
                If you are a curator, critic, cultural manager or gallery owner,
                do not hesitate to propose your project to us.
              </h3>
            </div>
            <div
              className="col-md-6 col-sm-12 col-xs-1"
              style={{ textAlign: "right" }}
            >
              <div style={{ textAlign: "center" }}>
                <a href="mailto:hola@harddiskmuseum.com">
                  <button
                    className="btn-hdm"
                  >
                    Contact us
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="section-hdm">
          <div className="row ">
            <div className="spacer-double  container"></div>
            <div
              className="col-md-12 col-sm-6 col-xs-1"
              style={{ textAlign: "center" }}
            >
              <hr
                className="hr-effects3"
                style={{ height: "0.3rem", marginBottom: "1rem" }}
              />
            </div>
            <div className="col-lg-12  " style={{ textAlign: "center" }}>
              <h2>Harddiskmuseum</h2>
            </div>

            <div
              className="col-md-12 col-sm-12 col-xs-1 btn-hdm-social"
              style={{ textAlign: "center" }}
            >
              <a href="https://discord.gg/cKeeyTv4c2" target="_blank">
                <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>
                  Join Our Discord Chanel
                </button>
              </a>
            </div>

            <div
              className="col-md-12 col-sm-12 col-xs-1 btn-hdm-social"
              style={{ textAlign: "center" }}
            >
              <a href="https://harddiskmuseum.com/" target="_blank">
                <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>
                  Visit the Museum
                </button>
              </a>
            </div>

            <div
              className="col-md-12 col-sm-12 col-xs-1 btn-hdm-social"
              style={{ textAlign: "center" }}
            >
              <a href="https://harddiskmuseum.com/news/" target="_blank">
                <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>
                  Our blog
                </button>
              </a>
            </div>
          </div>
          <div className="spacer-double  container"></div>
        </div>
        <section className="row" style={{ textAlign: "center" }}>
          <div className="col-md-12 col-sm-12 col-xs-1">
            <Community />
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default Homeone;