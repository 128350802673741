import React from 'react';
import Footer from '../components/footer';
import { Link } from '@reach/router';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

export const Privacy = () => (
    <div>
        <GlobalStyles />

        <section className='jumbotron breadcumb no-bg'>
            <div className='mainbreadcumb'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-8 offset-md-2 text-center">
                            <h1>Privacy Policy</h1>
                            <div className="spacer-20"></div>
                            <form className="row" id='form_sb' name="myForm">
                                {/*<div className="col text-center">
                                    <input className="form-control" id='name_1' name='name_1' placeholder="type your question here" type='text' /> <button id="btn-submit"><i className="arrow_right"></i></button>
                                </div>*/}
                            </form>
                            <div className="spacer-20"></div>

                            <p className="mt-0">721</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='container'>
            <div className="row">
                <div className="col-lg-12 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>1. An overview of data protection</h4>
                            <p>
                                General information
                                The following information will provide you with an easy to navigate overview of what will happen with your personal data when you visit this website. The term “personal data” comprises all data that can be used to personally identify you. For detailed information about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.

                                Data recording on this website
                                Who is the responsible party for the recording of data on this website (i.e. the “controller”)?

                                The data on this website is processed by the operator of the website, whose contact information is available under section “Information Required by Law” on this website.

                                How do we record your data?

                                We collect your data as a result of your sharing of your data with us. This may, for instance be information you enter into our contact form.

                                Other data shall be recorded by our IT systems automatically or after you consent to its recording during your website visit. This data comprises primarily technical information (e.g. web browser, operating system or time the site was accessed). This information is recorded automatically when you access this website.

                                What are the purposes we use your data for?

                                A portion of the information is generated to guarantee the error free provision of the website. Other data may be used to analyze your user patterns.

                                What rights do you have as far as your information is concerned?

                                You have the right to receive information about the source, recipients and purposes of your archived personal data at any time without having to pay a fee for such disclosures. You also have the right to demand that your data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this consent at any time, which shall affect all future data processing. Moreover, you have the right to demand that the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a complaint with the competent supervising agency.

                                Please do not hesitate to contact us at any time under the address disclosed in section “Information Required by Law” on this website if you have questions about this or any other data protection related issues.

                                Analysis tools and tools provided by third parties
                                There is a possibility that your browsing patterns will be statistically analyzed when your visit this website. Such analyses are performed primarily with what we refer to as analysis programs.

                                For detailed information about these analysis programs please consult our Data Protection Declaration below.
                            </p>
                            {/*} <Link to="" className="btn-main m-auto">Read more</Link>*/}
                        </div>
                    </div>
                </div>

                {/*<div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>What happens if my buyer didn't pay for the auction they won?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Why buy these NFTs/What makes them valuable</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Can I profit from this?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>What info can I change on my item?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How do I know what I'm buying is legit and not a fake?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How to sell digital items</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How to view my NFTs in MetaMask?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How do I delete or burn my NFTs?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How do I find the contract address for my NFTs?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Do you have an OpenSea integration?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                            </div>*/}
            </div>
        </section>

        <Footer />
    </div>

);