import { Canvas } from "react-three-fiber";
import React, { Suspense } from "react";
import { softShadows } from "@react-three/drei";
import { OrbitControls, FlyControls } from "drei";
import { useLoader } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import glb from '../../assets/NONBINARY_SPACE.glb'

softShadows();
const ThreeScene = ({zoom}) => {
  const gltf = useLoader(GLTFLoader, glb)
  return (
    <>
      <Suspense fallback={'rendering'}>

        <Canvas
          shadowMap
          colorManagement
          camera={{ position: [-5, 40, 120], fov: 60 }}
        >
          <Suspense fallback={'rendering'}>

            <color attach="background" args={"white"} />
            <ambientLight intensity={0.3} />
            <directionalLight
              castShadow
              position={[0, 10, 0]}
              intensity={1.5}
              shadow-mapSize-width={1024}
              shadow-mapSize-height={1024}
              shadow-camera-far={50}
              shadow-camera-left={-10}
              shadow-camera-right={10}
              shadow-camera-top={10}
              shadow-camera-bottom={-10}
            />
            <group>
              <Suspense fallback={'rendering'}>
                <primitive object={gltf.scene} />
              </Suspense>
            </group>
          </Suspense>

          <FlyControls
            autoForward={false}
            dragToLook={false}
            movementSpeed={8.0}
            rollSpeed={0.5}
          />
          {zoom === "true" ? (
            <OrbitControls autoRotate autoRotateSpeed={0.3} enableZoom={true} />
          ) : (
            <OrbitControls autoRotate autoRotateSpeed={0.3} enableZoom={false} />

          )}
        </Canvas>
      </Suspense>

    </>
  );
};

export default ThreeScene;
