import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as ImgIcon } from "../../assets/iconmonstr-file-25.svg";
import { ReactComponent as VidIcon } from "../../assets/iconmonstr-file-31.svg";
import { ReactComponent as ThreeDIcon } from "../../assets/3d_icon_upload.svg";
import { ReactComponent as UploadIcon } from "../../assets/iconmonstr-upload-11.svg";
import { uploadFromBlobAsyncImage, uploadFromBlobAsyncVideo, uploadFromBlobAsyncThreeD } from "../../services/firebase/storage";
import Loader from "react-loader-spinner";
// import { Spinner, Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";

export const UploadComponentTwo = ({
  onPrevButton,
  onNextButton,
  state,
  setState,
  inputChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  // const [fileUrl, setFileUrl] = useState(null)
  const continueButton = (e) => {
    e.preventDefault();
    onNextButton();
  };
  const backButton = (e) => {
    e.preventDefault();
    onPrevButton();
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles?.[0];

    if (!file) {
      return;
    }

    setIsLoading(true);
    setError(null);
    setMessage(null);

    try {
      if (state.image === true) {
        const imageUrl = await uploadFromBlobAsyncImage({
          blobUrl: URL.createObjectURL(file),
          name: `${file.name}_${Date.now()}`,
        });
        setState({...state, fileUrl: imageUrl});
      }
      if (state.video === true) {
        const videoUrl = await uploadFromBlobAsyncVideo({
          blobUrl: URL.createObjectURL(file),
          name: `${file.name}_${Date.now()}`,
        });
        setState({...state, fileUrl: videoUrl});
      }
      if (state.threed === true) {
        const threedUrl = await uploadFromBlobAsyncThreeD({
          blobUrl: URL.createObjectURL(file),
          name: `${file.name}_${Date.now()}`,
        });
        setState({...state, fileUrl: threedUrl});
      }
      console.log(state)
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
      return;
    }

    setIsLoading(false);
    setMessage("File was uploaded 👍");
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div className="upload-container-position">
      <div className="row">
        <div className="upload-container col-md-12 col-sm-6 col-xs-1">
          <hr className="hr-effects3" style={{ height: "0.3rem" }} />
        </div>
        <div className="spacer-double"></div>
        <div className="aligncenter col-lg-12">
          <h2>Upload your file</h2>
          <br />
          <p>Max 100mg</p>
        </div>
      </div>
      {state.image === true ? (
        <div>
          <div className="itm-container-upload">
            <div className="itm-upload">
              <div className="nft_coll_upload nft_coll">
                <div className="nft_coll_info">
                  <div {...getRootProps({ className: "nft_coll_center" })}>
                    <input {...getInputProps()} />
                    <span>
                      <p>.jpg, .png, .pdf, .gif</p>
                    </span>
                    <span>
                      <h4>Image</h4>
                    </span>

                    <span>
                      <i>
                        <ImgIcon className="imgicon-small" />
                      </i>
                    </span>
                    <span>
                    {isLoading ? (
                      <div className="margin-top">
                        <Loader type="Oval" color="#000000" height={100} width={100}/>
                      </div>
                ) : (
                      <i>
                        <UploadIcon className="uploadicon" />
                      </i>)}
                    </span>
                  </div>
                </div>
                { isDragActive ? (
                  <div className="nft_coll_info3">
                    <p>Drop here</p>
                  </div>
                ) : (
                  <div className="nft_coll_info3">
                    <p>Drop here or browse</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null }
      {state.video === true ? (
        <div>
          <div className="itm-container-upload">
            <div className="itm-upload">
              <div className="nft_coll_upload nft_coll">
                <div className="nft_coll_info">
                  <div {...getRootProps({ className: "nft_coll_center" })}>
                    <input {...getInputProps()} />
                    <span>
                      <p>.mp4</p>
                    </span>
                    <span>
                      <h4>Video</h4>
                    </span>

                    <span>
                      <i>
                        <VidIcon className="imgicon-small" />
                      </i>
                    </span>
                    <span>
                    {isLoading ? (
                      <div className="margin-top">
                        <Loader type="Oval" color="#000000" height={100} width={100}/>
                      </div>
                ) : (
                      <i>
                        <UploadIcon className="uploadicon" />
                      </i>)}
                    </span>
                  </div>
                </div>
                { isDragActive ? (
                  <div className="nft_coll_info3">
                    <p>Drop here</p>
                  </div>
                ) : (
                  <div className="nft_coll_info3">
                    <p>Drop here or browse</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null }
      {state.threed === true ? (
        <div>
          <div className="itm-container-upload">
            <div className="itm-upload">
              <div className="nft_coll_upload nft_coll">
                <div className="nft_coll_info">
                  <div {...getRootProps({ className: "nft_coll_center" })}>
                    <input {...getInputProps()} />
                    <span>
                      <p>.glb</p>
                    </span>
                    <span>
                      <h4>3D</h4>
                    </span>

                    <span>
                      <i>
                        <ThreeDIcon className="imgicon-small" />
                      </i>
                    </span>
                    <span>
                    {isLoading ? (
                      <div className="margin-top">
                        <Loader type="Oval" color="#000000" height={100} width={100}/>
                      </div>
                ) : (
                      <i>
                        <UploadIcon className="uploadicon" />
                      </i>)}
                    </span>
                  </div>
                </div>
                { isDragActive ? (
                  <div className="nft_coll_info3">
                    <p>Drop here</p>
                  </div>
                ) : (
                  <div className="nft_coll_info3">
                    <p>Drop here or browse</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null }
        {/* {(error || message) && (
          <Alert
            status={error ? "error" : "success"}
            w={250}
            borderRadius={5}
            m={2}
          >
            <AlertIcon />
            <AlertDescription w={200}>{error || message}</AlertDescription>
          </Alert>
        )} */}
        <div className="row">
          <div className="upload-container-btn col-lg-12 ">
            <button className="basic-btn-black" onClick={backButton}>
              Back
            </button>

            <button className="basic-btn" onClick={continueButton}>
              Next
            </button>
          </div>
        </div>
    </div>
  );
};
