import React, { useState, useRef, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';
import LOGOC2 from "../img/721_logo_color.svg"
import { newMemberNews } from "../../services/firebase/api"


const Countdown = () => {
    const [timerDays, setTimerDays] = useState('00');
    const [timerHors, setTimerHors] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerseconds, setTimerseconds] = useState('00');

    // const [modalShow, setModalShow] = React.useState(false);

    let interval = useRef();

    const startTimer = () => {
        const countdownDate = new Date('Sep 01, 2022 00:00:00').getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(interval.current);

            } else {
                setTimerDays(days);
                setTimerHors(hours);
                setTimerMinutes(minutes);
                setTimerseconds(seconds);
            }

        }, 1000);
    };

    // async function addMember(data) {
    //     console.log(data)
    //     try {
    //         return newMemberNews(data)
    //     } catch (error) {
    //         console.log("member error", error)
    //     }
    // }

    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });

    return (
        <>
            <div className="timer-container ">
                <div className="timer" >
                    <div className="timer-numbers">
                        <div className="">
                            <div className="p-countdown1">{timerDays}</div>
                            <p className="p-txt-countdown">Days</p>
                        </div>
                        <div className="">
                            <p className="p-countdown2" >{timerHors}</p>
                            <p className="p-txt-countdown">Hours</p>
                        </div>
                        <div className="">
                            <p className="p-countdown3">{timerMinutes}</p>
                            <p className="p-txt-countdown" >Minutes</p>
                        </div>
                        <div className="">
                            <p className="p-countdown4">{timerseconds}</p>
                            <p className="p-txt-countdown" >Seconds</p>
                        </div>
                    </div>

                    <div className="" style={{ margin: "1rem" }}>
                        <p className="p-txt-countdown2">Left for the opening of 721 Crypto Folder at HARDDISKMUSEUM</p>
                    </div>
                </div>
                
            </div>
        </>









    );
};



export default Countdown;