import React, { useState } from "react";
import UploadComponentOne from "./UploadComponentOne";
import { UploadComponentTwo } from "./UploadComponentTwo";
import UploadComponentThree from "./UploadComponentThree";
import { createFile } from "../../services/firebase/api";
import UploadComponentFour from "./UploadComponentFour";

var initialState = {
  step: 1,
  image: false,
  video: false,
  threed: false,
  glb: null,
  fileUrl: null,
  // title: "",
  // description: "",
  // edition: "",
  // units: "",
  // price: "",
};

const WizardForm = ({ context }) => {
  
  const [state, setState] = useState(initialState);

  var initialData = {
    artworkUrl: state.fileUrl,
    title: "",
    description: "",
    edition: "Limited edition",
    units: "",
    price: "",
  };
  const [data, setData] = useState(initialData);

  const onSubmit = async (data) => {
    await createFile(data);
  };

  const onPrevButton = () => {
    const { step } = state;
    setState({ ...state, step: step - 1 });
  };
  const onNextButton = () => {
    const { step } = state;
    setState({ ...state, step: step + 1 });
  };

  const inputChange = (input, value) => (e) => {
    setState({
      ...state,
      [input]: e.target.value,
    });
    if (value) {
      setState({
        ...state,
        [input]: value,
      });
    }
  };

  const { step } = state;
  const {
    image,
    video,
    threed,
    fileUrl,
    title,
    description,
    edition,
    units,
    price,
  } = state;
  const values = {
    image,
    video,
    threed,
    fileUrl,
    title,
    description,
    edition,
    units,
    price,
  };

  switch (step) {
    case 1:
      return (
        <UploadComponentOne
          onNextButton={onNextButton}
          inputChange={inputChange}
          state={state}
          setState={setState}
          values={values}
        />
      );
    case 2:
      return (
        <UploadComponentTwo
          onPrevButton={onPrevButton}
          onNextButton={onNextButton}
          inputChange={inputChange}
          state={state}
          setState={setState}
          values={values}
          context={context}
        />
      );
    case 3:
      return (
        <UploadComponentThree
          onPrevButton={onPrevButton}
          onNextButton={onNextButton}
          inputChange={inputChange}
          onSubmit={onSubmit}
          state={state}
          data={data}
          setData={setData}
          values={values}
        />
      );
    case 4:
      return (
        <UploadComponentFour
          state={state}
          data={data}
        />
      );
  }
};

export default WizardForm;
