import React from 'react';
import { Tabs, Tab } from "react-bootstrap";
import Footer from '../components/footer';
import Header from '../menu/header';
import LOGOC from "../img/721_logo_color.svg"
import { Link } from '@reach/router';
import Community from '../components/community';
import Hdm from "../img/HDM.png"

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const aboutUs = () => {
  return (
    <div>
      <Header />
      <div className="row logo-btn-tap-container" style={{ marginTop: "9rem" }}>
        <div className="col-md-12 col-sm-2 col-xs-1 logo-tap-container h-1" style={{ textAlign: "center", background: "none" }}>
          <NavLink to="/">
            <img src={LOGOC} alt="Imagen PNG alternativa" style={{ width: "8rem", height: "8rem", marginTop: "1rem" }} />
          </NavLink>
        </div>
      </div>

      <div className='row '>
        <div className="col-md-12 col-sm-6 col-xs-1 h-2" style={{ textAlign: "center", marginTop: "2rem" }}>
          <hr className="hr-effects3" style={{ height: "0.3rem" }} />
        </div>
        <div className="spacer-double h-3"></div>
        <div className='col-lg-12' style={{ textAlign: "center" }}>
          <h2>About</h2>
        </div>

        <div className="col-md-12 col-sm-6 col-xs-1">
          <img className='about-back' src={LOGOC} alt="Imagen PNG alternativa" />
        </div>
      </div>

      <div className='row'>
        <div className='p-about1 col-md-6 col-sm-6 col-xs-1 '>
          <h1>Our history</h1>
          <hr className="hr-effects6" style={{ height: "0.3rem" }} />
          <p >
            <strong>ERC721</strong> corresponde con las siglas de un estándar
            de desarrollo en la <strong>blockchain</strong> que ha
            revolucionado el modelo en el que entendemos un archivo digital.
            En <strong>2013</strong> el artista <strong>Solimán Lopez</strong>, perseguido por
            esta obsesión de tratar los archivos digitales 
            como objetos únicos, fundó el <strong>Harddiskmuseum</strong>.
            Este es el espacio criptográfico del Museo y un
            nuevo entorno que viene a complementar la labor
            del proyecto en estos últimos años.<br /> <br />
            Este espacio se organiza a través exposiciones
            comisariadas y <strong>“colecciones Harddiskmuseum”</strong>
            cuales se identifican discursos comunes y se
            relaciona el trabajo de artistas ya pertenecientes
            al Museo, así como invitados.
          </p>
        </div>

        <div className='p-about2 col-md-6 col-sm-6 col-xs-1'>
          <h1>Our view</h1>
          <hr className="hr-effects7" style={{ height: "0.3rem" }} />
          <p >
            <strong>ERC721</strong> corresponde con las siglas de un estándar
            de desarrollo en la <strong>blockchain</strong> que ha 
            revolucionado el modelo en el que entendemos un archivo digital.
            En <strong>2013</strong> el artista <strong>Solimán Lopez</strong>, perseguido por
            esta obsesión de tratar los archivos digitales
            como objetos únicos, fundó el <strong>Harddiskmuseum</strong>.
            Este es el espacio criptográfico del Museo y un
            nuevo entorno que viene a complementar la labor
            del proyecto en estos últimos años.<br /> <br />
            Este espacio se organiza a través exposiciones
            comisariadas y <strong>“colecciones Harddiskmuseum”</strong>  en las
            cuales se identifican discursos comunes y se
            relaciona el trabajo de artistas ya pertenecientes
            al Museo, así como invitados.
          </p>
        </div>
      </div>



      <div>
        <img src={Hdm} alt="harddiskmuseum" style={{ width: "100%" }} />
      </div>

      <section className='' style={{ background: "#EAEAEA", marginTop: "" }}>
        <div className='row '>
          <div className="col-md-12 col-sm-6 col-xs-1 container" style={{ textAlign: "center", marginTop: "" }}>
            <hr className="hr-effects3" style={{ height: "0.3rem" }} />
          </div>
          <div className="spacer-double  container"></div>
          <div className='col-lg-12  ' style={{ textAlign: "center" }}>
            <h2>Harddiskmuseum</h2>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Become a Harddiskmuseum artist</button>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Visit the Museum</button>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Our blog</button>
          </div>
        </div>
      </section>

      <section className="row" style={{ textAlign: "center" }}>
        <div className="col-md-12 col-sm-1 col-xs-1" >
          <Community />
        </div>
      </section>



      <Footer />
    </div>

  );
}
export default aboutUs;