import React from 'react'
import FormCreateCollection from '../components/FormCreateCollection';

const AdminCollections = () => {
    return (
        <div className="admin-collections">
            <div className="admin-collections-title">
                <h1>Admin Collectibles</h1>
            </div>
            <FormCreateCollection />
        </div>
    )
}

export default AdminCollections;
