import { storage } from './firebase';

// export const uploadFromBlobImages = async ({blobUrl, name}) => {
//     if(!blobUrl || !name) return null;

//     try {
//         const blob = await fetch(blobUrl).then((r) => r.blob())
//         const snapshot = await storage.ref(`/images/${name}`).put(blob);
//         return await snapshot.ref.getDownloadURL();
//     } catch (err) {
//         throw err
//     }
// }

// export const uploadFromBlobVideos = async ({blobUrl, name}) => {
//     if(!blobUrl || !name) return null;

//     try {
//         const blob = await fetch(blobUrl).then((r) => r.blob())
//         const snapshot = await storage.ref('videos').child(name).put(blob);
//         return await snapshot.ref.getDownloadURL()
//     } catch (err) {
//         throw err
//     }
// }

// export const uploadFromBlob3D = async ({blobUrl, name}) => {
//     if(!blobUrl || !name) return null;

//     try {
//         const blob = await fetch(blobUrl).then((r) => r.blob())
//         const snapshot = await storage.ref('threed').child(name).put(blob);
//         return await snapshot.ref.getDownloadURL()
//     } catch (err) {
//         throw err
//     }
// }
export const uploadFromBlobAsyncImage = async ({ blobUrl, name }) => {
    if (!blobUrl || !name) return null
  
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob())
      const snapshot = await storage.ref('images').child(name).put(blob)
      return await snapshot.ref.getDownloadURL()
    } catch (error) {
      throw error
    }
  }
export const uploadFromBlobAsyncVideo = async ({ blobUrl, name }) => {
    if (!blobUrl || !name) return null
  
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob())
      const snapshot = await storage.ref('videos').child(name).put(blob)
      return await snapshot.ref.getDownloadURL()
    } catch (error) {
      throw error
    }
  }
export const uploadFromBlobAsyncThreeD = async ({ blobUrl, name }) => {
    if (!blobUrl || !name) return null
  
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob())
      const snapshot = await storage.ref('threed').child(name).put(blob)
      return await snapshot.ref.getDownloadURL()
    } catch (error) {
      throw error
    }
  }