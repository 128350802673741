import React from 'react';
import Fade from 'react-reveal/Fade'
import blueRoomLogo from '../img/blueRoom.png';
import CSICLogo from '../img/CSIC_LOGO.jpeg';
import ESATLogo from '../img/ESAT_LAB.png';
import EsproncedaLogo from '../img/Espronceda logo square.jpeg';
import HDMLogo from '../img/logoHDM_B.png';
import theartmarketLogo from '../img/theartmarket_logo.jpeg';
import UASLogo from '../img/LOGO_UAS.png';

import Footer from '../components/footer';


const PartnersSection = () => {
    return (
        <>
            <div className="container" style={{ marginTop: "12rem", marginBottom: "4rem" }}>
                <div className="seo_sec_title text-center mb_70">
                    <Fade bottom><h2 className="title-font st--c-YygXh st--c-YygXh-ibiyHhy-css">Partners</h2></Fade>

                </div>
                <div className='col-md-12 col-sm-12 col-xs-1 p-creating'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed scelerisque, ante sit amet mollis mattis,
                        quam enim sagittis dolor, vitae sollicitudin metus quam vel nisi. Etiam facilisis, nibh ac interdum auctor,
                        neque velit ultricies eros, in dictum diam ante sed ante. Mauris enim dui, tristique vel lacinia ac, scelerisque
                        eget elit. Suspendisse potenti. Praesent ultrices orci ipsum, ac commodo odio posuere sed. Phasellus euismod accumsan
                        ipsum, commodo pharetra diam facilisis ut. Sed dictum facilisis posuere. Vestibulum bibendum, orci quis vulputate
                        consequat, arcu ex congue velit, quis varius risus odio non augue. Suspendisse pretium ante dui, vel vulputate lacus
                        vulputate non. Vestibulum condimentum, libero et vulputate dignissim, mauris felis lobortis purus, vel rhoncus quam
                        dolor ut lacus. Praesent eget sagittis ligula, eu porttitor lorem. Curabitur a posuere augue. Sed interdum, elit at
                        consectetur malesuada, felis tortor hendrerit ligula, sed lobortis lectus leo pellentesque sem. Curabitur nec nulla
                        luctus, porta ipsum sit amet, ultrices enim. Nulla ullamcorper ante a pharetra malesuada.
                    </p>
                </div>
                <div className="partner_logo_area_four">
                    <div className="row partner_info">
                        <div className="logo_item">
                            <a href=".#"><img src={UASLogo} alt="" /></a>
                        </div>
                        <div className="logo_item">
                            <a href=".#"><img src={HDMLogo} alt="" /></a>
                        </div>
                        <div className="logo_item">
                            <a href=".#"><img src={ESATLogo} alt="" /></a>

                        </div>
                        <div className="logo_item">
                            <a href=".#"><img src={EsproncedaLogo} alt="" /></a>
                        </div>
                        <div className="logo_item">
                            <a href=".#"><img src={blueRoomLogo} alt="" /></a>
                        </div>
                        <div className="logo_item_bigger">
                            <a href=".#"><img src={CSICLogo} alt="" /></a>

                        </div>
                        <div className="logo_item">
                            <a href=".#"><img src={theartmarketLogo} alt="" /></a>
                        </div>


                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PartnersSection;