import React, { memo } from "react";
import Footer from '../components/footer';
// import Pagination from '@mui/material/Pagination';

import { Link } from '@reach/router';
import LOGOC from "../img/721_logo_color.svg"
import FilesHDMartists from "../components/filesHDMartists";
import Community from '../components/community';
import Header from '../menu/header';



const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const HdmArtist = () => {

  return (
    <div>
      <Header />
      <section className="row" style={{marginTop:"2rem"}} >
        <div className="col-md-12 col-sm-2 col-xs-1 logo-tap-container" style={{ textAlign: "center" }}>
          <NavLink to="/">
            <img src={LOGOC} alt="Imagen PNG alternativa" style={{ width: "8rem", height: "8rem", marginTop: "1rem" }} />
          </NavLink>
        </div>
      </section>

      <div className='row'>
        <div className="col-md-12 col-sm-6 col-xs-1" style={{ textAlign: "center", marginTop: "2rem" }}>
          <hr className="hr-effects3" style={{ height: "0.3rem" }} />
        </div>
        <div className="spacer-double"></div>
        <div className='col-lg-12 ' style={{ textAlign: "center" }}>
          <h2>Files from Harddiskmuseum Artists</h2>
        </div>
        <div className="col-md-12 col-sm-6 col-xs-1" style={{ textAlign: "center", marginTop: "2rem" }}>
          <hr className="hr-effects3" style={{ height: "0.3rem" }} />
        </div>
      </div>

      <div className=" container-artisCurator-comp col-md-12 col-sm-6 col-xs-1">
        <FilesHDMartists/>
        {/* <Pagination count={10} showFirstButton showLastButton /> */}
      </div>

      <section className='' style={{ background: "#EAEAEA", marginTop: "" }}>
        <div className='row '>
          <div className="col-md-12 col-sm-6 col-xs-1 container" style={{ textAlign: "center", marginTop: "" }}>
            <hr className="hr-effects3" style={{ height: "0.3rem" }} />
          </div>
          <div className="spacer-double  container"></div>
          <div className='col-lg-12  ' style={{ textAlign: "center" }}>
            <h2>Harddiskmuseum</h2>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Become a Harddiskmuseum artist</button>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Visit the Museum</button>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Our blog</button>
          </div>
        </div>
      </section>

      <section className="row" style={{ textAlign: "center" }}>
        <div className="col-md-12 col-sm-1 col-xs-1" >
          <Community />
        </div>
      </section>
      <Footer />
    </div>
  )
};

export default memo(HdmArtist);