import React from "react";

import Modal from 'react-bootstrap/Modal';
import { Link } from '@reach/router';
import LOGOC from "../img/721-logo-p.svg"

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered


    >
      <div style={{ textAlign: "center", margin: "2rem" }}>
        <h2 style={{ marginTop: "1rem" }}>Welcome to 721 the Crypto Folder of the Harddiskmuseum !</h2>
      </div>
      <div style={{ margin: "2rem" }}>
        <marquee scrollamount="15" scrolldelay="2" >
          <h2 className="we-curate animate-charcter">Soon you will be able to enjoy interactive metaverses, unique collections and the content of amazing new artists and curators.</h2>
        </marquee>
      </div>
      <div style={{ textAlign: "center", marginBottom: "3rem" }}>
        <hr className="hr-effectsmodal" style={{ height: "0.3rem", margin: "2rem" }} />
        <button id="connect" variant="primary" onClick={props.onHide} style={{ width: "20%", height: "3rem", marginTop: "1rem" }}>Close</button>
      </div>


    </Modal>
  );
}

const Header = function () {

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div id="myHeader" className='navbar white'>


      <div className='row w-100-nav menu navbar-item'>
        

        <div className='mainside'>
          <button id="connect" variant="primary" onClick={() => setModalShow(true)} >Connect</button>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}
export default Header;