import React from "react";
import ReactPlayer from "react-player";
import { GLTFModel, AmbientLight, DirectionLight } from "react-3d-viewer";

const UploadComponentFour = ({ state, data }) => {
  return (
    <div className="upload-container-position">
      <div className="row">
        <div className="upload-container col-md-12 col-sm-6 col-xs-1">
          <hr className="hr-effects3" style={{ height: "0.3rem" }} />
        </div>
        <div className="spacer-double"></div>
        <div className="aligncenter col-lg-12">
          <h2>Congratulations!</h2>
          <br />
        </div>
      </div>
      <div>
        <div className="itm-container-upload">
          {state.image === true ? (
            <div
              style={{
                width: "490px",
                height: "auto",
                border: "1px solid black",
                borderRadius: "25px",
              }}
            >
              <img
                src={state.fileUrl}
                alt="1"
                style={{
                  width: "100%",
                  height: "auto",
                  border: "1px solid black",
                  borderRadius: "25px",
                }}
              />
            </div>
          ) : null}
          {state.video === true ? (
            <div
              style={{
                width: "490px",
                height: "auto",
                border: "1px solid black",
                borderRadius: "25px",
              }}
            >
              <ReactPlayer
                url={state.fileUrl}
                width="100%"
                height="100%"
                controls
              />
            </div>
          ) : null}
          {state.threed === true ? (
            <div
              style={{
                width: "502px",
                height: "502px",
                border: "1px solid black",
              }}
            >
              <GLTFModel src={state.fileUrl}>
                <AmbientLight color={0xffffff} />
                <DirectionLight
                  color={0xffffff}
                  position={{ x: 100, y: 200, z: 100 }}
                />
                <DirectionLight
                  color={0xff00ff}
                  position={{ x: -100, y: 200, z: -100 }}
                />
              </GLTFModel>
            </div>
          ) : null}
        </div>
        {/* <FileForm
          state={state}
          inputChange={inputChange}
          values={values}
          onSubmit={onSubmit}
        /> */}
        <div className="end-upload-container">
          <div className="end-upload-textbig">
            <h2>{data.title}</h2>
          </div>
          <div className="end-upload-text">
            <p>{data.description}</p>
          </div>
          <div className="end-upload-text">
            <p>{data.edition}</p>
          </div>
          {data.edition === "Limited edition" ? (
            <div className="end-upload-text">
              <p>{data.units} units</p>
            </div>
          ) : null}
          <div className="end-upload-textbig">
            <h3 style={{marginBottom: "5px"}}>{data.price}</h3>
            <p style={{ textAlign: "left", opacity: "0.3", fontSize: "14px", margin: "0px"}}>
          Matric = Euro
        </p>
          </div>
        </div>
        <div className="seeartworkbtn-container">
          <button className="seeartworkbtn">See your artwork</button>
        </div>
      </div>
    </div>
  );
};

export default UploadComponentFour;
