
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// export default firebaseConfig = {
//   apiKey: "AIzaSyAFWnmA2VmSdHVnFe6SVtcj_7iX2xsYS-Q",
//   authDomain: "hdm-9f1a6.firebaseapp.com",
//   databaseURL: "https://hdm-9f1a6-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "hdm-9f1a6",
//   storageBucket: "hdm-9f1a6.appspot.com",
//   messagingSenderId: "154092872172",
//   appId: "1:154092872172:web:3541b779b48a0f282760c9",
//   measurementId: "G-KKQVQ8KHXD"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// export default app;
import firebase from "firebase/compat/app";
import 'firebase/compat/storage'
import 'firebase/compat/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAFWnmA2VmSdHVnFe6SVtcj_7iX2xsYS-Q",
    authDomain: "hdm-9f1a6.firebaseapp.com",
    databaseURL: "https://hdm-9f1a6-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "hdm-9f1a6",
    storageBucket: "hdm-9f1a6.appspot.com",
    messagingSenderId: "154092872172",
    appId: "1:154092872172:web:3541b779b48a0f282760c9",
    measurementId: "G-KKQVQ8KHXD"
  };

export const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore();


export const storage = app.storage();

// export const auth = firebase.auth();

db.settings({
  timestampsInSnapshot: true,
})


// export default { app, db }

