import React from 'react';
import { Link } from '@reach/router';
import aboutUs from "../pages/aboutUs.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from "@fortawesome/free-brands-svg-icons"

import LOGOC from "../img/iconmonstr-discord-1.svg"
import iDiscord from "../img/iconmonstr-discord-1.svg";
import iFacebook from "../img/iconmonstr-facebook-3.svg";
import iInstagram from "../img/iconmonstr-instagram-11.svg";
import iLinkedin from "../img/iconmonstr-linkedin-3.svg";
import iTwitter from "../img/iconmonstr-twitter-3.svg";




const Community = () => {
    return (

        <div className="row" style={{ justifyContent: "center" }}>
            <div className="community col-md-4 col-sm-12 col-xs-12">
                <div style={{ marginBottom: "1.5rem" }}>
                    <hr className="hr-community" style={{ height: "0.3rem" }} />
                </div>

                <div >
                    <p className='p-community-text' style={{ marginBottom: "1.5rem" }} >Community</p>
                </div>

                <div className='' >
                    <a href="https://discord.gg/cKeeyTv4c2" target="_blank" className='icon-hov' ><img  src={iDiscord} /></a>
                    <a href="https://www.facebook.com/harddiskmuseum" target="_blank" rel="noopener noreferrer" className='icon-hov'><img  src={iFacebook} /></a>
                    <a href="https://www.instagram.com/harddiskmuseum/" target="_blank" rel="noopener noreferrer" className='icon-hov'><img  src={iInstagram} /></a>
                    <a href="https://www.linkedin.com/company/harddiskmuseum/" target="_blank" rel="noopener noreferrer" className='icon-hov'><img  src={iLinkedin} /></a>
                    <a href="https://twitter.com/solimanlopez" target="_blank" rel="noopener noreferrer" style={{ marginRight: "0rem" }} className='icon-hov'><img src={iTwitter} /></a>
                </div>
            </div>
            <div className="col-md-2 col-sm-12 col-xs-1"> </div>

            <div className="col-md-4 col-sm-12 col-xs-1 pad">
                <div style={{ marginBottom: "1.5rem" }}>
                    <hr className="hr-community hr-comm" style={{ height: "0.3rem" }} />
                </div>
                <div >
                    <p className='p-community-text' style={{ marginBottom: "1.5rem" }} >Become a partner</p>
                </div>
                <div >
                    <a href='https://harddiskmuseum.com/partners/' target="_blank">
                        <button className="btn-hdm-degraded" >Be part of the project</button>
                    </a>
                </div>
            </div>
        </div>


    );
};

export default Community;

