import React from "react";

import LOGOC from "../img/721_logo_color.svg"
import { Link } from '@reach/router';


const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const CanvasInfo2 = () => {
  return (
    <div className="row logo-btn-tap-container">
      

      <div className="col-md-4 col-sm-2 col-xs-1 logo-tap-container" style={{ textAlign: "center" }}>
      <NavLink to="/">
          <img src={LOGOC} alt="Imagen PNG alternativa" style={{ width: "8rem", height: "8rem", marginTop: "1rem" }} />
        </NavLink>
      </div>
      <div className="col-md-1 col-sm-2 col-xs-1 "></div>

      <div className="col-md-2 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
        <button className="btn-experience3" style={{ marginTop: "0.3rem" }}>
          <span style={{color:"white", }}> Upload artwork</span>
          <span className="notification-number"  >1</span></button>
        
      </div>
      <div className="col-md-1 col-sm-2 col-xs-1 "></div>



    </div>

  );
};

export default CanvasInfo2;
