import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselCollection } from "./constants";
import * as selectors from "../../store/selectors";
import { fetchHotCollections } from "../../store/actions/thunks";
import SelectExperience from "./SelectExperience";

import CustomPrevArrow from "./CustomPrevArrow";
import CustomNextArrow from "./CustomNextArrow";




const InvitationSlider = () => {
  const dispatch = useDispatch();
  const hotCollectionsState = useSelector(selectors.hotCollectionsState);
  const hotCollections = hotCollectionsState.data
    ? hotCollectionsState.data
    : [];

  useEffect(() => {
    dispatch(fetchHotCollections());
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div className="nft invitation-slider">
      <Slider {...carouselCollection} {...settings}>
        {hotCollections &&
          hotCollections.map((item, index) => (
            <SelectExperience
              style={{ marginTop: "0.5rem" }}
              key={index}
              index={index + 1}
              avatar={item.avatar}
              banner={item.banner}
              username={item.username}
              uniqueId={item.unique_id}
            />
          ))}
      </Slider>
      <div className="" style={{ height: "3rem", background: "" }}></div>
    </div>
  );
};

export default InvitationSlider;
