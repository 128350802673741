import React from "react";

import { ReactComponent as NextArrow } from "../../assets/arrow_right.svg";

const CustomNextArrow = ({ className, style, onClick }) => {
  return (
    <NextArrow
      className={className}
      style={{
        ...style,
        display: "block",
        background: "none",
        borderRadius: "0px",
        borderTopLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        marginRight: "-30px"
      }}
      onClick={onClick}
    />
  );
};

export default CustomNextArrow;
