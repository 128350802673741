import React from "react";

import { Link } from '@reach/router';
import iInstagram from "../img/iconmonstr-instagram-11.svg";
import iTwitter from "../img/iconmonstr-twitter-3.svg";
import iLink from "../img/iconmonstr-link-1.svg";
import EditProfile from "../pages/editProfile";
import Card from 'react-bootstrap/Card'


const NavLink = props => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            return {
                className: isCurrent ? 'active' : 'non-active',
            };
        }}
    />
);

const FilesHDMartists = ({ index }) => {
    return (
        <div className="row artist-card-container" >
            <div className="col-md-3 col-sm-6 col-xs-1" >
                <div className='itm' index={index}>
                    <div className="nft_coll" >
                        <div className="nft_wrap">
                            <span>
                                <img style={{ width: "100%", height: "12rem", objectFit: "cover" }}
                                    src="https://uca5934dd4d39e9bd30a28420c55.previews.dropboxusercontent.com/p/thumb/ABZaWshU5b2qTTIz5y__JQ12mKTbJv87uXWXIg6DmZ36gecBEclXgJomWgPyo6K1VqpxZVufxWBeOHTkUDOH7vN5NIbOVpX6XmYEm62pwSsFWH4QZNw2eFvdDgd2Yh0L1dWG21jlHbXA9w_blZj9jPwO6uI1KOAbX8Zn1pEE2zRhD9toiKHlb36h4NMU8KyPJHnlHH4am95MeI2jCJQil9T3HI84eOd-0jAO0QhqXUdHXYwfSeMT_hpvivn_MY8NWE_PL8MfAppwggOizBXaiFI_v-jHDFJrmnxqV6WRMbG7vuBVpdvLb139VA3RTyz8zQRTGlKm4QouE_KAT_q8ti7AXIMOQsOziNe7s1MDRI8K-UeaZPvW_4tb12QzCoTAIYw/p.jpeg"
                                    className="lazy img-fluid" alt="" />
                            </span>
                        </div>

                        <div className="row" style={{ display: "flex", padding: "1rem" }}>
                            <div className="col-md-6 col-sm-6 col-xs-1">
                                <div className="nft_coll_ac  " style={{ display: "flex" }}>
                                    <div style={{ marginRight: "0.5rem" }}>
                                        <img className="" src="https://www.xatobaxestion.com/wp-content/uploads/2016/09/default-user-img.jpg" alt="" />
                                    </div>
                                    <div className="">
                                        <p style={{ width: "7rem", marginTop: "-0.0rem" }}>
                                            Artist name:
                                        </p>
                                        <p style={{ width: "7rem", marginTop: "-1rem" }}>
                                            Arwork title
                                        </p>
                                        <p style={{ width: "7rem", marginTop: "-1rem" }}>
                                            <strong>Price:</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-1" >
                                <p style={{ textAlign: "right", marginTop: "0rem" }}>
                                    Curated by:
                                    
                                </p>
                            </div>

                            <div className="col-md-12 col-sm-6 col-xs-1 nft_coll_info3" style={{ textAlign: "right", marginTop: "0rem" }}>
                                <NavLink className="" to="/collections" id="connect" >
                                    <button className=".btn-hov-section">Collect</button>
                                </NavLink>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            {/* nos quedamos sólo con el de arriba. El index tiene que hacer que los pinte todos */}

            <div className="col-md-3 col-sm-6 col-xs-1" >
                <div className='itm' index={index}>
                    <div className="nft_coll" >
                        <div className="nft_wrap">
                            <span>
                                <img style={{ width: "100%", height: "12rem", objectFit: "cover" }}
                                    src="https://uca5934dd4d39e9bd30a28420c55.previews.dropboxusercontent.com/p/thumb/ABZaWshU5b2qTTIz5y__JQ12mKTbJv87uXWXIg6DmZ36gecBEclXgJomWgPyo6K1VqpxZVufxWBeOHTkUDOH7vN5NIbOVpX6XmYEm62pwSsFWH4QZNw2eFvdDgd2Yh0L1dWG21jlHbXA9w_blZj9jPwO6uI1KOAbX8Zn1pEE2zRhD9toiKHlb36h4NMU8KyPJHnlHH4am95MeI2jCJQil9T3HI84eOd-0jAO0QhqXUdHXYwfSeMT_hpvivn_MY8NWE_PL8MfAppwggOizBXaiFI_v-jHDFJrmnxqV6WRMbG7vuBVpdvLb139VA3RTyz8zQRTGlKm4QouE_KAT_q8ti7AXIMOQsOziNe7s1MDRI8K-UeaZPvW_4tb12QzCoTAIYw/p.jpeg"
                                    className="lazy img-fluid" alt="" />
                            </span>
                        </div>

                        <div className="row" style={{ display: "flex", padding: "1rem" }}>
                            <div className="col-md-6 col-sm-6 col-xs-1">
                                <div className="nft_coll_ac  " style={{ display: "flex" }}>
                                    <div style={{ marginRight: "0.5rem" }}>
                                        <img className="" src="https://www.xatobaxestion.com/wp-content/uploads/2016/09/default-user-img.jpg" alt="" />
                                    </div>
                                    <div className="">
                                        <p style={{ width: "7rem", marginTop: "-0.0rem" }}>
                                            Artist name:
                                        </p>
                                        <p style={{ width: "7rem", marginTop: "-1rem" }}>
                                            Arwork title
                                        </p>
                                        <p style={{ width: "7rem", marginTop: "-1rem" }}>
                                            <strong>Price:</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-1" >
                                <p style={{ textAlign: "right", marginTop: "0rem" }}>
                                    Curated by: <br />
                                    Collected by:
                                </p>
                            </div>

                            <div className="col-md-12 col-sm-6 col-xs-1 nft_coll_info3" style={{ textAlign: "right", marginTop: "0rem" }}>
                                <NavLink className="" to="/collections" id="connect" >
                                    <button className=".btn-hov-section">Collect</button>
                                </NavLink>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <div className="col-md-3 col-sm-6 col-xs-1" >
                <div className='itm' index={index}>
                    <div className="nft_coll" >
                        <div className="nft_wrap">
                            <span>
                                <img style={{ width: "100%", height: "12rem", objectFit: "cover" }}
                                    src="https://uca5934dd4d39e9bd30a28420c55.previews.dropboxusercontent.com/p/thumb/ABZaWshU5b2qTTIz5y__JQ12mKTbJv87uXWXIg6DmZ36gecBEclXgJomWgPyo6K1VqpxZVufxWBeOHTkUDOH7vN5NIbOVpX6XmYEm62pwSsFWH4QZNw2eFvdDgd2Yh0L1dWG21jlHbXA9w_blZj9jPwO6uI1KOAbX8Zn1pEE2zRhD9toiKHlb36h4NMU8KyPJHnlHH4am95MeI2jCJQil9T3HI84eOd-0jAO0QhqXUdHXYwfSeMT_hpvivn_MY8NWE_PL8MfAppwggOizBXaiFI_v-jHDFJrmnxqV6WRMbG7vuBVpdvLb139VA3RTyz8zQRTGlKm4QouE_KAT_q8ti7AXIMOQsOziNe7s1MDRI8K-UeaZPvW_4tb12QzCoTAIYw/p.jpeg"
                                    className="lazy img-fluid" alt="" />
                            </span>
                        </div>

                        <div className="row" style={{ display: "flex", padding: "1rem" }}>
                            <div className="col-md-6 col-sm-6 col-xs-1">
                                <div className="nft_coll_ac  " style={{ display: "flex" }}>
                                    <div style={{ marginRight: "0.5rem" }}>
                                        <img className="" src="https://www.xatobaxestion.com/wp-content/uploads/2016/09/default-user-img.jpg" alt="" />
                                    </div>
                                    <div className="">
                                        <p style={{ width: "7rem", marginTop: "-0.0rem" }}>
                                            Artist name:
                                        </p>
                                        <p style={{ width: "7rem", marginTop: "-1rem" }}>
                                            Arwork title
                                        </p>
                                        <p style={{ width: "7rem", marginTop: "-1rem" }}>
                                            <strong>Price:</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-1" >
                                <p style={{ textAlign: "right", marginTop: "0rem" }}>
                                    Curated by: <br />
                                    Collected by:
                                </p>
                            </div>

                            <div className="col-md-12 col-sm-6 col-xs-1 nft_coll_info3" style={{ textAlign: "right", marginTop: "0rem" }}>
                                <NavLink className="" to="/collections" id="connect" >
                                    <button className=".btn-hov-section">Collect</button>
                                </NavLink>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <div className="col-md-3 col-sm-6 col-xs-1" >
                <div className='itm' index={index}>
                    <div className="nft_coll" >
                        <div className="nft_wrap">
                            <span>
                                <img style={{ width: "100%", height: "12rem", objectFit: "cover" }}
                                    src="https://uca5934dd4d39e9bd30a28420c55.previews.dropboxusercontent.com/p/thumb/ABZaWshU5b2qTTIz5y__JQ12mKTbJv87uXWXIg6DmZ36gecBEclXgJomWgPyo6K1VqpxZVufxWBeOHTkUDOH7vN5NIbOVpX6XmYEm62pwSsFWH4QZNw2eFvdDgd2Yh0L1dWG21jlHbXA9w_blZj9jPwO6uI1KOAbX8Zn1pEE2zRhD9toiKHlb36h4NMU8KyPJHnlHH4am95MeI2jCJQil9T3HI84eOd-0jAO0QhqXUdHXYwfSeMT_hpvivn_MY8NWE_PL8MfAppwggOizBXaiFI_v-jHDFJrmnxqV6WRMbG7vuBVpdvLb139VA3RTyz8zQRTGlKm4QouE_KAT_q8ti7AXIMOQsOziNe7s1MDRI8K-UeaZPvW_4tb12QzCoTAIYw/p.jpeg"
                                    className="lazy img-fluid" alt="" />
                            </span>
                        </div>

                        <div className="row" style={{ display: "flex", padding: "1rem" }}>
                            <div className="col-md-6 col-sm-6 col-xs-1">
                                <div className="nft_coll_ac  " style={{ display: "flex" }}>
                                    <div style={{ marginRight: "0.5rem" }}>
                                        <img className="" src="https://www.xatobaxestion.com/wp-content/uploads/2016/09/default-user-img.jpg" alt="" />
                                    </div>
                                    <div className="">
                                        <p style={{ width: "7rem", marginTop: "-0.0rem" }}>
                                            Artist name:
                                        </p>
                                        <p style={{ width: "7rem", marginTop: "-1rem" }}>
                                            Arwork title
                                        </p>
                                        <p style={{ width: "7rem", marginTop: "-1rem" }}>
                                            <strong>Price:</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-1" >
                                <p style={{ textAlign: "right", marginTop: "0rem" }}>
                                    Curated by: <br />
                                    Collected by:
                                </p>
                            </div>

                            <div className="col-md-12 col-sm-6 col-xs-1 nft_coll_info3" style={{ textAlign: "right", marginTop: "0rem" }}>
                                <NavLink className="" to="/collections" id="connect" >
                                    <button className=".btn-hov-section">Collect</button>
                                </NavLink>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}
export default FilesHDMartists;