import React from "react"

import Header from '../menu/header';
import Footer from '../components/footer';
import Fade from 'react-reveal/Fade'

const TermsPrivacy = () => {
    return (
        <>
            <div>
                <Header />
                <section className='row sect'>
                    <div className="col-md-12 col-sm-12 col-xs-1">
                        <Fade bottom><h2 className="st--c-YygXh st--c-YygXh-ibiyHhy-css">Terms of Service</h2></Fade>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h4>Welcome</h4>
                        <p >
                            NMUAS ESTUDIO, SL (721,” “we,” “us,” “our”) provides its marketplace and services (described below) to you
                            (“you” or “User") through its website, platform, and marketplace located at https://721.harddiskmuseum.com/ (the “Platform”), subject
                            to the following Terms of Service (as amended from time to time, the “Terms”).  By signing up for an account on the Platform
                            or otherwise using or accessing the Platform, you acknowledge that you have read and agree to these Terms.  The Privacy Policy
                            and all such additional terms, guidelines, and rules as set forth on the Platform are hereby incorporated by reference into these
                            Terms and expressly agreed to and acknowledged by the User.
                        </p>
                        <p>
                            <strong>
                                PLEASE READ THESE TERMS OF PLATFORM CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL
                                RIGHTS, REMEDIES, AND OBLIGATIONS.  THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO
                                BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST 721 ON AN INDIVIDUAL BASIS, NOT AS A
                                PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY,
                                INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW.
                            </strong>
                        </p>
                        <p>
                            We reserve the right, at our sole discretion, to change or modify portions of these Terms of Service at any time.  If we do this,
                            we will post the changes on this page and will indicate at the top of this page the date these terms were last revised.  We will also
                            notify you, either through the Platform user interface, in an email notification or through other reasonable means.  Any such changes will
                            become effective no earlier than fourteen (14) days after they are posted, except that changes addressing new functions of the Platform will
                            be effective immediately.  Your continued use of the Platform and after the date any such changes become effective constitutes your acceptance of the new Terms of Service.
                        </p>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h4>What is 721 NFTs marketplace</h4>
                        <p>
                            721 is a platform for artists (“Creators”) and collectors (“Collectors”) to sell, purchase, list for auction, make offers,
                            and bid (each a “Transaction”) on digital art represented on a non-fungible Ethereum-based tokens (“Digital Artwork”).
                        </p>
                        <p>
                            <strong>Smart-Contract Enabled.</strong>  The Digital Artwork on 721 NFTs marketplace is represented on smart contracts on the Ethereum blockchain that
                            provides an immutable ledger of all transactions that occur on 721 (“Smart Contracts”).  This means that all Digital Artwork
                            is outside of the control of any one party, including 721, and is subject to many risks and uncertainties.  We neither own nor
                            control MetaMask, Coinbase, the Ethereum network, your browser, or any other third party site, product, or service that you might access,
                            visit, or use for the purpose of enabling you to use the various features of the Platform. We will not be liable for the acts or omissions
                            of any such third parties, nor will we be liable for any damage that you may suffer as a result of your transactions or any other interaction
                            with any such third parties.  The User understands that your Ethereum public address will be made publicly visible whenever you engage in a transaction on the Platform.
                        </p>
                        <p>
                            <strong>Noncustodial</strong>.  While 721 offers a marketplace for Digital Artwork, it does not buy, sell, or ever take custody or possession of any Digital Artwork.
                            The Platform facilitates User collection of Digital Artwork, but neither 721 nor the Platform are custodians of any Digital Artwork.
                            The User understands and acknowledges that the Smart Contracts do not give 721 custody, possession, or control of any Digital Artwork or
                            cryptocurrency at any time for the purpose of facilitating transactions on the Platform.  You affirm that you are aware and acknowledge
                            that 721 is a non-custodial service provider and has designed this Platform to be directly accessible by the Users without any involvement
                            or actions taken by 721 or any third-party.  As a marketplace, 721 cannot make any representation or guarantee that Creators will achieve
                            any particular outcome as the result of listing their Digital Artwork on 721.
                        </p>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h1>How do I use 721?</h1>
                        <p>
                            <strong>Your Registration Obligations:</strong>  Anyone can browse 721 without registering for an account. You may be required to
                            register with 721 in order to access and use certain features on the Platform, such as participating as a Creator
                            or Collector.  If you choose to register for the Platform, you agree to provide and maintain true, accurate, current,
                            and complete information about yourself as prompted by our registration form.  Registration data and certain other
                            information about you are governed by our Privacy Policy.  You must be at least 13 years old to register for an
                            account as a Creator, and at least 18 years old to place a bid on any Digital Artwork. If you are between 13 and 18
                            years old, you must have the expressed permission of a parent or legal guardian who can accept these Terms on your
                            behalf. You are responsible for anything that occurs when anyone is signed in to your account, as well as the security
                            of the account.
                        </p>
                        <p>
                            <strong>Member Account, Password, and Security:</strong> You are responsible for maintaining the confidentiality of your account and password,
                            if any, and are fully responsible for any and all activities that occur under your password or account.  You agree to (a)
                            immediately notify 721 of any unauthorized use of your password or account or any other breach of security, and (b)
                            ensure that you exit from your account at the end of each session when accessing 721.  721 will not be liable
                            for any loss or damage arising from your failure to comply with this Section.
                        </p>
                        <p>
                            <strong>Connecting your Wallet:</strong>  In order to participate as a Creator or Collector in the marketplace, you must connect to a browser
                            extension called MetaMask. MetaMask is an electronic wallet which allows you to purchase , store, and engage in transactions
                            using the native Ethereum cryptocurrency, ETH.  All transactions on 721 are in the native Ethereum cryptocurrency, ETH.
                        </p>
                        <p>
                            <strong>Modifications to the Platform:</strong> 721 reserves the right to modify or discontinue, temporarily or permanently,
                            the Platform (or any part thereof) with or without notice.  You agree that 721 will not be liable to you or to
                            any third party for any modification, suspension, or discontinuance of the Platform.
                        </p>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h1>What are the rules for using 721?</h1>
                        <p>When using 721, no User is allowed to:</p>
                        <ol>
                            <li type="disc">
                                manipulate the price of a Digital Artwork in any way, including bidding on your own items, preventing bidding, or using 721 to conceal economic activity.
                            </li>
                            <li type="disc">
                                email or otherwise upload any content that (i) infringes any intellectual property or other proprietary rights of any party; (ii) you do not have a right to
                                upload under any law or under contractual or fiduciary relationships; (iii) contains software viruses or any other computer code, files or programs designed
                                to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; (iv) poses or creates a privacy or security
                                risk to any person; (v) constitutes unsolicited or unauthorized advertising, promotional materials, commercial activities and/or sales, “junk mail,” “spam,” “chain
                                letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any other form of solicitation; (vi) is unlawful, harmful, threatening, abusive, harassing, tortious,
                                excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable;
                                or (vii) in the sole judgment of 721, is objectionable or which restricts or inhibits any other person from using or enjoying the Platform, or which may
                                expose 721 or its users to any harm or liability of any type;
                            </li>
                            <li type="disc">
                                interfere with or disrupt the Platform or servers or networks connected to the Platform, or disobey any requirements, procedures, policies or regulations of
                                networks connected to the Platform ; or
                            </li>
                            <li type="disc">
                                violate any applicable local, state, national or international law, or any regulations having the force of law, including but not limited to the U.S.
                                Department of Treasury’s Office of Foreign Assets Control (“OFAC”), or which would involve proceeds of any unlawful activity;
                            </li>
                            <li type="disc">
                                impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;
                            </li>
                            <li type="disc">

                            </li>
                            <li type="disc">solicit personal information from anyone under the age of 18;</li>
                            <li type="disc">
                                harvest or collect email addresses or other contact information of other Users from the Platform by electronic or other means for the purposes of
                                sending unsolicited emails or other unsolicited communications;
                            </li>
                            <li type="disc">
                                advertise or offer to sell or buy any goods or services for any business purpose that is not specifically authorized;
                            </li>
                            <li type="disc">
                                further or promote any criminal activity or enterprise or provide instructional information about illegal activities,
                                including for the purpose of concealing economic activity, laundering money, or financing terrorism;
                            </li>
                            <li type="disc">
                                obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Platform;
                            </li>
                            <li type="disc">
                                use any robot, spider, site search/retrieval application, or other device to retrieve or index any portion of the Platform or the content posted on the Platform,
                                or to collect information about its Users for any unauthorized purpose;
                            </li>
                            <li type="disc">create user accounts by automated means or under false or fraudulent pretenses; </li>
                            <li type="disc">
                                access or use the Platform for the purpose of creating a product or service that is competitive with any of our products or services.
                            </li>
                        </ol>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h1>How do I become a creator?</h1>
                        <p>
                            Artists need an invitation or to become a Creator on 721.  Selling invitations in return for ETH or any other favor is strictly prohibited.
                            If 721 becomes aware that any invitation is being sold to a third party, 721 may suspend or otherwise terminate your access to 721.
                            721 maintains complete discretion in selecting the artists in its marketplace, and makes no guarantees or promises that any artists will be approved
                            as Creators even if 721 solicited the request.
                        </p>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h1>What are the intellectual property rights on the Platform?</h1>
                        <h4>Creator Rights</h4>
                        <p>
                            The Creator owns all legal right, title, and interest in all intellectual property rights underlying the Digital Artwork minted by the
                            Creator on the Platform, including but not limited to copyrights and trademarks. As the copyright owner, the Creator has the right to reproduce,
                            prepare derivative Digital Artwork, distribute, and display or perform the Digital Artwork.
                            Creators hereby acknowledges, understands, and agrees that selling a Digital Artwork on Foundation constitutes an express representation, warranty,
                            and covenant that the Creator has not, will not, and will not cause another to sell, tokenize, or create another cryptographic token representing a
                            digital collectible for the same Digital Artwork, excepting, without limitation, the Creator’s ability to sell, tokenize, or create a cryptographic
                            token or other digital asset representing a legal, economic, or other interest relating to any of the exclusive rights belonging to the Creator under copyright law.
                            The Creator hereby acknowledges, understands, and agrees that launching a Digital Artwork on Foundation constitutes an express and affirmative grant to Foundation,
                            its affiliates and successors a non-exclusive, world-wide, assignable, sublicensable, perpetual, and royalty-free license to make copies of, display, perform,
                            reproduce, and distribute the Digital Artwork on any media whether now known or later discovered for the broad purpose of operating, promoting, sharing, developing,
                            marketing, and advertising the Platform, or any other purpose related to Foundation, including without limitation, the express right to: (i) display or perform the
                            Digital Artwork on the Platform, a third party platform, social media posts, blogs, editorials, advertising, market reports, virtual galleries, museums, virtual environments,
                            editorials, or to the public; (ii) create and distribute digital or physical derivative Digital Artwork based on the Digital Artwork; (iii) indexing the Digital Artwork in
                            electronic databases, indexes, catalogues; and (iv) hosting, storing, distributing, and reproducing one or more copies of the Digital Artwork within a distributed file keeping
                            system, node cluster, or other database (e.g., IPFS) or causing, directing, or soliciting others to do so.
                            Creators expressly represent and warrant that their Digital Artwork listed on Foundation contains only original content otherwise authorized for use by the Creator,
                            and does not contain unlicensed or unauthorized copyrighted content, including any imagery, design, audio, video, human likeness, or other unoriginal content not created by
                            the Creator, not authorized for use by the Creator, not in the public domain, or otherwise without a valid claim of fair use, the Creator further represents and warrants that
                            it has permission to incorporate the unoriginal content
                        </p>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1">
                        <Fade bottom><h2 className="st--c-YygXh st--c-YygXh-ibiyHhy-css">Privacy</h2></Fade>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <p>
                            This Privacy Policy document contains types of information that is
                            collected and recorded by 721 NFTs and how we use it.
                            If you have additional questions or require more information about our Privacy Policy, do not
                            hesitate to contact us.
                            This Privacy Policy applies only to our online activities and is valid for visitors to our website with
                            regards to the information that they shared and/or collect in 721 NFTs. This policy is not
                            applicable to any information collected offline or via channels other than this website.
                        </p>
                    </div>


                </section>
                <Footer />
            </div>
        </>
    );
}
export default TermsPrivacy;