import React, { useState } from "react";
import { ReactComponent as ImgIcon } from "../../assets/iconmonstr-file-25.svg";
import { ReactComponent as VidIcon } from "../../assets/iconmonstr-file-31.svg";
import { ReactComponent as ThreeDIcon } from "../../assets/3d_icon_upload.svg";

const UploadComponentOne = ({onNextButton, state, setState, inputChange }) => {
  // const [extension, setExtension] = useState()

  const onClickImage = () =>{
    if(state.image === false) {
      console.log('image to true');
      setState({...state, image: true, video: false, threed: false});
    } else {
      console.log('image to false');
      setState({...state, image: false});

    }
  }
  const onClickVideo = () =>{
    if(state.video === false) {
      console.log('image to true');
      setState({...state, video: true, image: false, threed: false});
    } else {
      console.log('image to false');
      setState({...state, video: false});

    }
  }
  const onClick3D = () =>{
    if(state.threed === false) {
      console.log('image to true');
      setState({...state, threed: true, image: false, video: false});
    } else {
      console.log('image to false');
      setState({...state, threed: false});

    }
  }
  
  const continueButton = e => {
    e.preventDefault();
    onNextButton();
  }
  return (
    <div className="upload-container-position">
      <div className="row">
        <div
          className="upload-container col-md-12 col-sm-6 col-xs-1"
        >
          <hr className="hr-effects3" style={{ height: "0.3rem" }} />
        </div>
        <div className="spacer-double"></div>
        <div className="aligncenter col-lg-12" >
          <h2>Create NFTs</h2>
          <br />
          <p>Select an extension</p>
        </div>
      </div>
      <div>
        <div className="itm-container-upload">
          <div className="itm-upload">
            <div className="nft_coll_upload nft_coll">
              <div className="nft_coll_info">
                <div className="nft_coll_center">
                  <span>
                    <p>.jpg, .png, .pdf, .gif</p>
                  </span>
                  <span>
                    <h4>Image</h4>
                  </span>

                  <span>
                    <i>
                      <ImgIcon
                      className="imgicon"
                      />
                    </i>
                  </span>
                </div>
              </div>
              <div className="nft_coll_info3">
                <button onClick={onClickImage}>{state.image === true ? ('Selected') :('Select extension') }</button>
              </div>
            </div>
          </div>
          <div className="itm-upload">
            <div className="nft_coll_upload nft_coll">
              <div className="nft_coll_info">
                <div className="nft_coll_center">
                  <span>
                    <p>.mp4</p>
                  </span>
                  <span>
                    <h4>Video</h4>
                  </span>

                  <span>
                    <i>
                      <VidIcon
                      className="vidicon"
                      />
                    </i>
                  </span>
                </div>
              </div>
              <div className="nft_coll_info3">
              <button onClick={onClickVideo}>{state.video === true ? ('Selected') :('Select extension') }</button>

              </div>
            </div>
          </div>
          <div className="itm-upload">
            <div className="nft_coll_upload nft_coll">
              <div className="nft_coll_info">
                <div className="nft_coll_center">
                  <span>
                    <p>.glb</p>
                  </span>
                  <span>
                    <h4>3D</h4>
                  </span>
                  <div>
                    <span>
                      <i>
                        <ThreeDIcon
                        className="threedicon"
                        />
                      </i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="nft_coll_info3">
              <button onClick={onClick3D}>{state.threed === true ? ('Selected') :('Select extension') }</button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="upload-container-two col-lg-12"
        >
          <p>Max 100mg</p>
        </div>
      </div>
      <div className="row">
        <div
          className="upload-container-two col-lg-12"
        >
          <button className="basic-btn" onClick={continueButton} >Next</button>
        </div>
      </div>
    </div>
  );
};

export default UploadComponentOne;
