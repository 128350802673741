import React from 'react';

import Header from '../menu/header';
import Footer from '../components/footer';
import Fade from 'react-reveal/Fade';

const Faq = () => {
    return (
        <>
            <div>
                <Header />
                <section className='row sect'>
                    <div className="col-md-12 col-sm-12 col-xs-1">
                       <Fade bottom><h2 className="st--c-YygXh st--c-YygXh-ibiyHhy-css">FAQ</h2></Fade> 
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <p style={{ textAlign: "center" }}>
                            Frequently asked questions about 721 NFTs marketplace
                        </p>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h2>What happens if my buyer didn't pay for the auction they won?</h2>
                        <h4>When you mint and sell an NFT on Foundation:</h4>
                        <p>
                            If your auction had bidders - once its ended you'll be able to see the status of the auction.
                        </p>

                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h2>What happens if my buyer didn't pay for the auction they won?</h2>
                        <h4>Auctions</h4>
                        <p>
                            If your auction had bidders - once its ended you'll be able to see the status of the auction.
                        </p>


                        <h4>Message the buyer</h4>
                        <p>
                            You can directly message the buyer to discuss the item - or give them a gentle reminder they only have 3 days to submit their payment.
                            But if they don't make a payment in time...

                        </p>


                        <h4>Mark the auction as NOT PAID </h4>
                        <p>
                            If the buyer doesn't send the payment within 3 days - you can mark the auction as not paid.
                            This will give a blackmark to the buyer - and prohibit them from bidding on any other items for 3 days.
                            You should only do this if they have failed to reply to any messages and did not send any payment.
                        </p>
                        <p>
                            If your buyer does not submit payment - don't worry - you can just list your item for sale again - or message
                            the second-highest bidder and ask them if they are interested - then send them the link to the new listing page.
                        </p>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h2>Why buy these NFTs/What makes them valuable</h2>
                        <p>
                            There are a ton of reasons to buy these items/NFTs.
                        </p>
                        <ol>
                            <li>
                                <strong>Unique</strong> - Each NFT is unique, it's only one of its kind. You cannot make another NFT that has the same token
                                number on the same smart contract. Meaning that there will only ever be one of that token. This is verifiable
                                by the blockchain and can be seen by anyone on the ledger.
                            </li>
                            <li>
                                <strong>Copyrights</strong> - Your NFT might come with the copyrights to the asset if the seller chooses to transfer them.
                                The buyer of a token with copyrights transferred will be free to replicate or use it for commercial purposes.
                                However, the NFT seller may set some conditions of use in the NFT description or metadata
                            </li>
                            <li>
                                <strong>Rare</strong> - Since they are unique and cannot be copied, they are rare. Most of the time, artists release NFTs
                                in limited quantities - it’s not often that they would mint 1000s of NFTs. Therefore, you can safely assume <strong>you would be one
                                    of the few people in the world that owns a collectible item, and that it is rare enough to give resale benefits.</strong>
                            </li>

                            <li>
                                <strong>Collectible</strong> - These NFTs/items are collectibles. Collectibles are valuable because they are rare, and they usually appreciate in
                                value because quantities cannot be diluted. Buying them to resell them can earn you significant amounts of money - there are plenty
                                of people who do it on a daily basis and make a living out of flipping NFTs.
                            </li>
                            <li>
                                <strong>Downloadable</strong> Files - NFT creators can include bonus downloadable files that only the current owner of the NFT can download, making
                                it unlockable to the owner. If your NFT contains game assets, music, a PDF, or more locked inside it, you might want to buy the NFT
                                just to download that song/PDF/etc.
                            </li>
                            <li>
                                <strong>Immutable</strong> - No one can change the metadata on the token, no one can remove the image or edit the name of the token, etc. This means it
                                will never change, it will never be removed, it cannot be taken down off the blockchain. This is what gives it so much value and collectibility.
                            </li>
                            <li>
                                <strong>Forever</strong> - NFTs are forever. As the data doesn't change and it exists on the blockchain forever, you will always have that token.
                                You can always resell that token if you want. It's like buying a bar of gold - you own it, and you can do with it what you want.
                            </li>
                            <li>
                                <strong>Resellable</strong> - You can always resell your NFT. You SHOULD resell your NFT and trade it. You can make some serious cash trading NFTs. Some NFTs have gone for 20,000 USD + when the original buyer only bought it for a few thousand dollars. Making them over 15,000 USD in a short time in ONE trade!
                            </li>
                        </ol>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h2>Can I profit from this?</h2>
                        <p>
                            Can I profit from this?
                        </p>
                        <p>
                            Finding good NFTs to buy and reselling them is exactly what you should do!
                        </p>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h2>What info can I change on my item?</h2>
                        <p>
                            Only some info can be changed on your NFT after Minting
                        </p>

                        <h4> Things that cannot be changed:</h4>
                        <p>
                            Token ID, contract address, name, title, image, additional metadata such as adding of unlocked /bonus content and royalities percentage or smart contract associated with the NFT.
                        </p>

                        <h4> Things that can be changed</h4>
                        <p>
                            Copyright transfer and resellable. You can only edit the information of the item if it's listed for sale.
                            If your item is not for sale, you cannot edit any information.
                        </p>
                    </div>
                </section>
                <Footer />

            </div>
        </>
    );
}
export default Faq;