import React, { memo } from "react";
import Footer from "../components/footer";
import InvitationSlider from "../components/InvitationSlider";
import { ReactComponent as ArrowIcon } from "../../assets/arrow_down.svg";
import UploadComponentOne from "../components/UploadComponentOne";
import UploadComponentTwo from "../components/UploadComponentTwo";
import UploadComponentThree from "../components/UploadComponentThree";
import WizardForm from "../components/WizardForm";
const UploadArtwork = () => {
  return (
    <div>
      <div>
        <div className="center row">
          <div className="upload-container col-md-12 col-sm-6 col-xs-1">
            <hr className="hr-effects3" style={{ height: "0.3rem" }} />
          </div>
          <div className="spacer-double"></div>
          <div className="aligncenter col-lg-12">
            <h2>Invitations available</h2>
          </div>
        </div>
        <InvitationSlider />
      </div>
      <div className="text-container-upload">
        <p>
          Ha sido invitado por el equipo de Harddiskmuseum a participar en estas
          colecciones y experiencias
          <br /> <br />
          Por favor, complete las subidas de sus obras.
        </p>
        <span>
          <i>
            <ArrowIcon className="arrowicone" />
          </i>
        </span>
      </div>
      <WizardForm />
      <Footer />
    </div>
  );
};

export default memo(UploadArtwork);
