import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { Link } from '@reach/router';

const NavLink = props => (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        return {
          className: isCurrent ? 'active' : 'non-active',
        };
      }}
    />
  );

const Collection = () => {
    return (
        <div className="col-md-12 col-sm-6 col-xs-1" >
            <div className='itm' index="">
                <div className="nft_coll" >
                    <div className="nft_wrap">
                        <span>
                            <img style={{ width: "100%", height: "12rem", objectFit: "cover" }} src="https://uceac61b88122fd963044ebdaaac.previews.dropboxusercontent.com/p/thumb/ABXH-a9qRijwm14We2LxCchhoALHaOR9go0ywWoA8yEfKfSdS1U4M0u_ehqdjMLDKBYXolS7rOasz30qPoM3RP9uxI2OxSBPMYGJoIu4lLPa39Axs0Qvv1bMXA-dp0jQatUwZq8h6-YO_tQxnGYrOj706--UiWCpRTdXhu2YVF7X1_3fu3xVcT8Rosw8Q-AKOXwhiQxdMsaq0XEXaI9QbCQIsfBg0jeYMtdHzSUFCuGNg7cs_qz55UAjdcN9rF_bXn1m2TDht7mvnnYczx3MWCk8vrDp_SlZwEhdq3ZNlDrU0Cg6XyY0V0cp3WHk33v_2fnNfanD9R5JIPsNUIBtN4loZZl455RKJ17viW-iegYYDnToM5DV8mskwkdqtGEmVrA/p.jpeg"
                            className="lazy img-fluid" alt="" /></span>
                    </div>

                    <div className="nft_coll_info">
                        <div>
                            <span onClick={() => window.open("/home", "_self")}>
                                <h6>Collection: </h6>
                            </span>
                            <span >Participants:</span>
                        </div>
                        <div>
                            <span onClick={() => window.open("", "_self")}>
                                <i className="ellipse" ><span>Collectors </span>
                                    <FontAwesomeIcon className="ellipse1" icon={faEllipsisV}></FontAwesomeIcon>
                                </i>
                            </span>
                        </div>
                    </div>

                    <div className="nft_coll_info2">
                        <div >
                            <span>Curated by:</span>
                        </div>
                    </div>

                    <div className="nft_coll_info3" style={{ textAlign: "center" }}>
                        <NavLink className="" to="/collections" id="" className=" btn-main "  >
                            <button>View</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default Collection;
