import React, { useState } from 'react';
import { Link } from '@reach/router';
import Modal from 'react-bootstrap/Modal';
import LOGOC from "../img/721-logo-p.svg"
import LOGOC2 from "../img/721_logo_color.svg"
import LOGOHDM from "../img/logo_HDM_white.svg"
import LOGOMCD from '../../assets/MCD_Gob_harddiskmuseum_white.svg'

import {newMemberNews} from "../../services/firebase/api"

const NavLink = props => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            return {
                className: isCurrent ? 'active' : 'non-active',
            };
        }}
    />
);

const Footer = () => {
    const [modalShow, setModalShow] = React.useState(false);


    async function addMember(data) {
        console.log(data)
        try {
            return newMemberNews(data)
        } catch (error) {
            console.log("member error", error)
        }
    }

    return (
        <footer className="footer-light" style={{ background: "#00DFE9" }}>
            <div className="container footer-container"  >

                <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-1">
                        <div className="widget" >
                            <NavLink to="/">
                                <img src={LOGOC} alt="Imagen PNG alternativa" style={{ width: "10rem", height: "10rem" }} />
                            </NavLink>

                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="widget">
                            <h5 className="footer-menu-txt" style={{ fontWeight: "400" }} >721
                                <hr className="hr-effects4" style={{ height: "0.1rem" }} /></h5>


                            <ul >
                                <li>
                                    <a href="mailto:hola@harddiskmuseum.com" style={{ color: "white" }}>HDM - Contact</a>
                                </li>
                                {/* onClick={() => setModalShow(true)} */}
                                <li>
                                    <NavLink to="/" style={{ color: "white" }}>Subscribe</NavLink>
                                </li>
                                <li>
                                    <a href='https://harddiskmuseum.com/artist-apply/' target="_blank" rel="noopener noferrer" style={{ color: "white" }}>Apply as Artist</a>
                                </li>
                                <li>
                                    <NavLink to="/" style={{ color: "white" }}>Participation guide</NavLink>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        addMember={addMember}
                    />



                    <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="widget">
                            <h5 style={{ color: "white", fontWeight:"400" }}>Resources
                                <hr className="hr-effects4" style={{ height: "0.1rem" }} />
                            </h5>
                            <ul>
                                <li><NavLink to="/" style={{ color: "white" }}>FAQ</NavLink></li>
                                <li><NavLink to="/" style={{ color: "white" }}>Terms & Privacy</NavLink></li>
                                <li><NavLink to="/" style={{ color: "white" }}>Partners</NavLink></li>
                                <li><Link to="" style={{ color: "white" }}>Suggestions</Link></li>

                            </ul>

                        </div>
                    </div>

                    <div className="col-md-3 col-sm-12 col-xs-12" style={{paddingBottom: "20px" }}>
                        <a href='https://harddiskmuseum.com/' target="_blank" rel="noopener noferrer" style={{marginTop: "4.5rem" }} >
                            <img className="hdm-logo" src={LOGOHDM} alt="Harddiskmuseum Logo Image" />
                        </a>
                        <div className="ministerio">
                            <h6>With the support of:</h6>
                            <img src={LOGOMCD} alt="Imagen PNG alternativa" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

{/*---------------MODAL------------------------- */ }
function MyVerticallyCenteredModal(props) {
    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        comment: ""
    };

    const [values, setValues] = useState(initialValues);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }

    const memberAdd = async (data) => {
        await props.addMember(data);
        console.log(data)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(values);
        memberAdd(values);
    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form onSubmit={(e) => handleSubmit(e)} method="POST" >
                <Modal.Body>
                    <div className="field-shift row">
                        <div className="col-md-12 col-sm-12 col-xs-1" style={{ textAlign: "center", marginTop: "1rem", marginBottom: "1rem" }}>
                            <img src={LOGOC2} alt="logo" style={{ marginBottom: "1rem", width: "12%" }} />
                            <h4>Join our newsletter</h4>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-1" style={{ marginBottom: "1rem" }} >
                            <label htmlFor="firstName"   >Name:</label>
                            <input type="text" name="firstName" tabindex="-1" placeholder="" id="b_name" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-1 " style={{ marginBottom: "1rem" }}>
                            <label htmlFor="lastName" >Lastname: </label>
                            <input type="text" name="lastName" tabindex="-1" placeholder="" id="b_name" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-1 " style={{ marginBottom: "1rem" }}>
                            <label htmlFor="email" >Email: </label>
                            <input type="email" name="email" tabindex="-1" placeholder="youremail@gmail.com" id="b_email" onChange={handleInputChange} />
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-1 ">
                            <label htmlFor="comment" >Comment: </label>
                            <textarea className="txt-area" name="comment" tabindex="-1" placeholder="Please comment" id="b_comment" onChange={handleInputChange}></textarea>
                        </div>
                    </div>
                    <Modal.Footer style={{ justifyContent: "center" }}>
                        <button className="btn-modal-newsletter" type="button" onClick={props.onHide}>Close</button>
                        <button className="btn-modal-newsletter " type="submit" onClick={props.onHide} >Subscribe</button>
                    </Modal.Footer>
                </Modal.Body>
            </form>
        </Modal>
    );
}


export default Footer;