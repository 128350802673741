import React from 'react';
import Footer from '../components/footer';
import { Link } from '@reach/router';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

const logintwo = () => (
    <div>
        <GlobalStyles />

        <section className='jumbotron breadcumb no-bg'>
            <div className='mainbreadcumb'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-8 offset-md-2 text-center">
                            <h1>Help Center</h1>
                            <div className="spacer-20"></div>
                            <form className="row" id='form_sb' name="myForm">
                                <div className="col text-center">
                                    <input className="form-control" id='name_1' name='name_1' placeholder="type your question here" type='text' /> <button id="btn-submit"><i className="arrow_right"></i></button>
                                </div>
                            </form>
                            <div className="spacer-20"></div>

                            <p className="mt-0">eg. create item, create wallet.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='container'>
            <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>What is Wallet Value on my Profile?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>What happens if my buyer didn't pay for the auction they won?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Why buy these NFTs/What makes them valuable</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Can I profit from this?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>What info can I change on my item?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How do I know what I'm buying is legit and not a fake?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How to sell digital items</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How to view my NFTs in MetaMask?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How do I delete or burn my NFTs?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>How do I find the contract address for my NFTs?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-box f-boxed style-3 text-center">
                        <div className="text">
                            <h4>Do you have an OpenSea integration?</h4>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam.</p>
                            <Link to="" className="btn-main m-auto">Read more</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />
    </div>

);
export default logintwo;