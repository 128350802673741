import React, { useEffect } from "react";
import Footer from '../components/footer';
import Community from '../components/community';
import WhatNext from '../components/whatNetxt';
import ArtistsCurator from '../components/artistsCurator';

import { Link } from '@reach/router';
import LOGOC from "../img/721_logo_color.svg"

import { fetchHotCollections } from "../../store/actions/thunks";
import ExperiencesList from '../components/experiencesList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import Header from '../menu/header';
import Experience from "../components/experience"






const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const Experiences = () => {

  return (
    <div>
      <Header />
      <div className="row logo-btn-tap-container" style={{ marginTop: "9rem" }}>
        <div className="col-md-12 col-sm-2 col-xs-1 logo-tap-container" style={{ textAlign: "center" }}>
          <NavLink to="/">
            <img src={LOGOC} alt="Imagen PNG alternativa" style={{ width: "8rem", height: "8rem", marginTop: "1rem" }} />
          </NavLink>
        </div>
      </div>

      <div className='collectible-container container no-top no-bottom'  >
        <div className='row'>
          <div className="col-md-12 col-sm-6 col-xs-1" style={{ textAlign: "center", marginTop: "2rem" }}>
            <hr className="hr-effects3" style={{ height: "0.3rem" }} />
          </div>
          <div className="spacer-double"></div>
          <div className='col-lg-12 ' style={{ textAlign: "center" }}>
            <h2>Experiences</h2>
            <p className="collections-txt-container">
              Las experiencias se basan en <strong>entornor 3D</strong>, realidades aumentadas y virtuales que ofrecen al espectador un modo diferente
              de acceso a la obra de arte y puerta de entrada a los conceptos y evoluciones del arte digital.<br/><br/>
              A rtravés de laproducción de estas experiencias expositivas, el <strong>Harddiskmuseum</strong> pretende construr poco a poco su multiverso de archivos
              digitales, donde la obra se encontrará en su espacio natal y natural, lo intangible. <br/><br/>
              El coleccionista puede adquirir las obras indepentientes o la experiencia completa.<br/><br/>
              Las experiencias son un contenido ideal para profesionales del arte, centro de arte, galerías, museos, festivales o colecciones ya que ofrecemos un servicio
              de instalación localmente en el espacio web del nuevo propietario. 
            </p>
          </div>
        </div>
        <Experience/>
      </div>

      <div className="text-container-colecciones" >
        <button className="btn-main inline lead" style={{ color: "black", background: "white" }}>Share this Collection</button>
      </div>

      {/* */}
      <section>
          <span> pagination here</span>
      </section>


      <section className='' style={{ background: "#EAEAEA", marginTop: "" }}>
        <div className='row '>
          <div className="col-md-12 col-sm-6 col-xs-1 container" style={{ textAlign: "center", marginTop: "" }}>
            <hr className="hr-effects3" style={{ height: "0.3rem" }} />
          </div>
          <div className="spacer-double  container"></div>
          <div className='col-lg-12  ' style={{ textAlign: "center" }}>
            <h2>Harddiskmuseum</h2>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Become a Harddiskmuseum artist</button>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Visit the Museum</button>
          </div>
          <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Our blog</button>
          </div>
        </div>
      </section>

      <section className="row" style={{ textAlign: "center" }}>
        <div className="col-md-12 col-sm-1 col-xs-1">
          <Community />
        </div>
      </section>

      <Footer />


    </div>
  );

}

export default Experiences;


