import { auth, db } from "./firebase";


// export const watchUserChanges = (callback) => {
//     const unsub = auth.onAuthStateChanged((user) => {
//       if (user && !user.isAnonymous) {
//           callback(user);
//       } else {
//           callback(null);
//       } 

      
//     });

//     return unsub;
// }

export const watchFiles = (callback) => {
    const unsub = db
    .collection('files')
    .onSnapshot((snapshot) => {
        const docs = []
        snapshot.forEach((doc) => {
            const data = doc.data();

            docs.push({
                ...data,
                id: doc.id,

            });
        });

        callback(docs);
    });
    return unsub;
}
export const watchExperiences = (callback) => {
    const unsub = db
    .collection('experiences')
    .onSnapshot((snapshot) => {
        const docs = []
        snapshot.forEach((doc) => {
            const data = doc.data();

            docs.push({
                ...data,
                id: doc.id,

            });
        });

        callback(docs);
    });
    return unsub;
}
export const watchCollections = (callback) => {
    const unsub = db
    .collection('collections')
    .onSnapshot((snapshot) => {
        const docs = []
        snapshot.forEach((doc) => {
            const data = doc.data();

            docs.push({
                ...data,
                id: doc.id,

            });
        });

        callback(docs);
    });
    return unsub;
}
