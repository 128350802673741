import React from 'react';
import { Router, Location, Redirect } from '@reach/router';
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
import Home from './pages/home';
import Home1 from './pages/home1';
import Home2 from './pages/home2';
import Explore from './pages/explore';
import Explore2 from './pages/explore2';
import Helpcenter from './pages/helpcenter';
// import Rangking from './pages/rangking';
//----------NAVBAR LINKS------------------
import Artists from './pages/artists';
import HdmArtist from './pages/hdmArtist';
import Collections from './pages/collections';
import Experiences from './pages/experiences';
import Colection from './pages/colection';
// import ItemDetail from './pages/ItemDetail';
import ItemDetailRedux from './pages/ItemDetailRedux';
import Author from './pages/Author';
import Wallet from './pages/wallet';
import Login from './pages/login';
import LoginTwo from './pages/loginTwo';
import Register from './pages/register';
import Price from './pages/price';
import Works from './pages/works';
import News from './pages/news';
import Create from './pages/create';
import Auction from './pages/Auction';
import Activity from './pages/activity';
import Contact from './pages/contact';
import ElegantIcons from './pages/elegantIcons';
import EtlineIcons from './pages/etlineIcons';
import FontAwesomeIcons from './pages/fontAwesomeIcons';
import Accordion from './pages/accordion';
import Alerts from './pages/alerts';
import Progressbar from './pages/progressbar';
import Tabs from './pages/tabs';
import AboutUs from './pages/aboutUs';
import UserProfile from './pages/userProfile';
import EditProfile from './pages/editProfile';

import { createGlobalStyle } from 'styled-components';
import { TermsService } from './pages/termsService';
import PartnersSection from './pages/PartnersSection';
import { Privacy } from './pages/privacy';



import AdminCollections from './pages/AdminCollections';
import UploadArtwork from './pages/UploadArtwork';
import NbExperience from './pages/nbExperience';
import CanvasInfo from "../components/components/CanvasInfo"
import ParticipationGuide from './pages/participationGuide'
import FAQ from './pages/faq'
import TermsPrivacy from './pages/termsPrivacy'



<FontAwesomeIcons path="/fontAwesomeIcons" />

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0,0), [location])
  return children
}

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id='routerhang'>
        <div key={location.key}>
          <Router location={location}>
            {children}
          </Router>
        </div>
      </div>
    )}
  </Location>
);

const app= () => (
  <div className="wraper">
  <GlobalStyles />
      <PosedRouter>
      <ScrollTop path="/">
        <Home2 exact path="/">
          <Redirect to="/home" />
        </Home2>
        <Home1 path="/home1" />
        <Home2 path="/home2" />
        <Explore path="/explore" />
        <Explore2 path="/explore2" />
        <Helpcenter path="/helpcenter" />
        <Artists path="/artists" />
        <HdmArtist path="/hdmArtist" />
        <Colection path="/colection" />
        <ItemDetailRedux path="/ItemDetail" />
        <Author path="/Author" />
        <UserProfile path="/userProfile" />
        <Login path="/login" />
        <LoginTwo path="/loginTwo" />
        <Register path="/register" />
        <Price path="/price" />
        <Works path="/works" />
        <News path="/news" />
        <Create path="/create" />
        <Auction path="/Auction" />
        <Activity path="/activity" />
        <Contact path="/contact" />
        <ElegantIcons path="/elegantIcons" />
        <EtlineIcons path="/etlineIcons" />
        <FontAwesomeIcons path="/fontAwesomeIcons" />
        <Accordion path="/accordion" />
        <Alerts path="/alerts" />
        <Progressbar path="/progressbar" />
        <Tabs path="/tabs" />
        <AboutUs path="/aboutUs" />
        <TermsService path="/termsService" />
        <Privacy path="/Privacy" />

        <PartnersSection path="/PartnersSection" />
        <EditProfile path="/editProfile" />
        <Collections path="/collections" />
        <Experiences path="/experiences" />
        <AdminCollections path="/admin" />
        <UploadArtwork path="/upload-artwork" />
        <NbExperience path="/nb-experience" />
        <ParticipationGuide path="/participationGuide" />
        <FAQ path="/faq"/>
        <TermsPrivacy path="/termsPrivacy"/>

        
        </ScrollTop>
      </PosedRouter>
    <ScrollToTopBtn />
    
  </div>
);
export default app;