import {db} from './firebase';


//Files
export async function createFile(data) {
    return await db
        .collection('files')
        .doc()
        .set(data);
}

export async function deleteFile(id) {
    return await db
        .collection('files')
        .doc(id)
        .delete();
}

export async function updateFile(id, data) {
    return await db
        .collection('files')
        .doc(id)
        .update(data);
}

//collections
export async function createCollection(data) {
    return await db
        .collection('collections')
        .doc()
        .set(data);
}

export async function deleteCollection(id) {
    return await db
        .collection('collections')
        .doc(id)
        .delete();
}

export async function updateCollection(id, data) {
    return await db
        .collection('collections')
        .doc(id)
        .update(data);
}

//Experiences
export async function createExperience(data) {
    return await db
        .collection('experiences')
        .doc()
        .set(data);
}

export async function deleteExperience(id) {
    return await db
        .collection('experiences')
        .doc(id)
        .delete();
}

export async function updateExperience(id, data) {
    return await db
        .collection('experiences')
        .doc(id)
        .update(data);
}
//newsletter
export async function newMemberNews(data) {
    return await db
        .collection('newsletter')
        .doc()
        .set(data);
}

