import React from "react";
import { ReactComponent as PrevArrow } from "../../assets/arrow_left.svg";

const CustomPrevArrow = ({ className, style, onClick }) => {
  return (
    <PrevArrow
      className={className}
      style={{
        ...style,
        display: "block",
        background: "none",
        borderRadius: "0px",
        borderTopRightRadius: "10px",
        borderBottomLeftRadius: "10px",
        marginLeft: "-30px"

      }}
      onClick={onClick}
    />
  );
};

export default CustomPrevArrow;
