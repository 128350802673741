import React from "react";

import { Link } from '@reach/router';
import iInstagram from "../img/iconmonstr-instagram-11.svg";
import iTwitter from "../img/iconmonstr-twitter-3.svg";
import iLink from "../img/iconmonstr-link-1.svg";
import EditProfile from "../pages/editProfile";
import Card from 'react-bootstrap/Card'


const NavLink = props => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            return {
                className: isCurrent ? 'active' : 'non-active',
            };
        }}
    />
);

const ArtistsAndCurator = ({ index, avatar, banner, username, uniqueId }) => {
    return (
        <div className="row" style={{ padding: "2rem", justifyContent:"center" }}>

            <div className="col-md-4 col-sm-6 col-xs-1" style={{ width: '400px', height:"569px" }} >
                <div className='itm' index={index}>
                    <div className="nft_coll" >
                        <div className="nft_wrap">
                            <span>
                                <img style={{ width: "100%", height: "12rem", objectFit: "cover" }}
                                    src="https://uceac61b88122fd963044ebdaaac.previews.dropboxusercontent.com/p/thumb/ABXH-a9qRijwm14We2LxCchhoALHaOR9go0ywWoA8yEfKfSdS1U4M0u_ehqdjMLDKBYXolS7rOasz30qPoM3RP9uxI2OxSBPMYGJoIu4lLPa39Axs0Qvv1bMXA-dp0jQatUwZq8h6-YO_tQxnGYrOj706--UiWCpRTdXhu2YVF7X1_3fu3xVcT8Rosw8Q-AKOXwhiQxdMsaq0XEXaI9QbCQIsfBg0jeYMtdHzSUFCuGNg7cs_qz55UAjdcN9rF_bXn1m2TDht7mvnnYczx3MWCk8vrDp_SlZwEhdq3ZNlDrU0Cg6XyY0V0cp3WHk33v_2fnNfanD9R5JIPsNUIBtN4loZZl455RKJ17viW-iegYYDnToM5DV8mskwkdqtGEmVrA/p.jpeg"
                                    className="lazy img-fluid" alt="" />
                            </span>
                        </div>

                        <div className="row" style={{ display: "flex", padding: "1rem" }}>
                            <div className="col-md-12 col-sm-6 col-xs-1">
                                <div className="nft_coll_ac  " style={{ display: "flex" }}>
                                    <div style={{ marginRight:"0.5rem" }}>
                                        <img className="" src="https://www.xatobaxestion.com/wp-content/uploads/2016/09/default-user-img.jpg" alt="" />
                                    </div>
                                    <div className="">
                                        <p style={{ width: "7rem",marginTop: "-0.0rem" }}>Artist name</p>
                                        <p style={{ width: "7rem", marginTop: "-1rem" }}>Artist</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-6 col-xs-1">
                                <ul className="artis_curator_view">
                                    <li >
                                        <a className="" href="https://twitter.com/solimanlopez" target="_blank" rel="noopener noreferrer" style={{ marginRight: "0rem" }}>@artistwitter <img style={{ width: "15px", height: "15px" }} src={iTwitter} /></a>
                                    </li>
                                    <li >
                                        <a className="" href="https://twitter.com/solimanlopez" target="_blank" rel="noopener noreferrer" style={{ marginRight: "0rem" }}>@artistinstagram <img style={{ width: "15px", height: "15px" }} src={iInstagram} /></a>
                                    </li>
                                    <li >
                                        <a className="" href="https://twitter.com/solimanlopez" target="_blank" rel="noopener noreferrer" style={{ marginRight: "0rem" }}>www.artistweb.com <img style={{ width: "15px", height: "15px" }} src={iLink} /></a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            

            
        </div>
    );
}
export default ArtistsAndCurator;