import React, { useState } from 'react';
import EditProfile from "../pages/editProfile";
import UProfile from "../pages/uProfile";

const initialState = {
  step: 1
}

const UserProfile = () => {
  const [state, setState] = useState(initialState);
  

  const nextStep = () => {
    const { step } = state;
    setState({ step: step + 1 });
  };

  const prevStep = () => {
    const { step } = state;
    setState({ step: step - 1 });
  };

    const { step } = state;

    switch (step) {
      case 1:
          return (
              <UProfile
                  nextStep={nextStep}
              />
          );
      case 2:
          return (
              <EditProfile
                  prevStep={prevStep}
              />
          );
  }
}

export default UserProfile;


