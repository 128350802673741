import React, { useState } from "react";

const FileForm = ({ state, onSubmit, data, setData, onNextButton }) => {
  // var initialData = {
  //   artworkUrl: state.fileUrl,
  //   title: "",
  //   description: "",
  //   edition: "Limited edition",
  //   units: "",
  //   price: "",
  // };
  // const [data, setData] = useState(initialData);

  const { title, description, edition, units, price } = data;

  const submitButton = (e) => {
    e.preventDefault();
    onSubmit(data);
    onNextButton();

  };

  const inputChange = (input, value) => (e) => {
    console.log(state);

    setData({
      ...data,
      [input]: e.target.value,
    });
    if (value) {
      setData({
        ...data,
        [input]: value,
      });
    }
  };

  return (
    <form onSubmit={submitButton} className="form-upload">
      <label htmlFor="title">
        <input
          type="text"
          id="title"
          name="title"
          value={title}
          onChange={inputChange("title")}
          placeholder="Artwork Title"
          className="form-upload-input"
        />
      </label>
      <label htmlFor="description">
        <textarea
          rows={10}
          cols={50}
          onChange={inputChange("description")}
          name="description"
          value={description}
          className="form-upload-textarea"
          placeholder='Description (no more than 800 characters)'
          maxLength={800}
        />
        <p style={{ textAlign: "right", opacity: "0.3" }}>
          {description?.length}/{800}
        </p>
      </label>
      <label htmlFor="edition">
        <select
          className="form-upload-select"
          value={edition}
          onChange={inputChange("edition")}
        >
          <option>Limited edition</option>
          <option>Unique piece</option>
        </select>
      </label>
      {edition === "Unique piece" ? (
        <div></div>
      ) : (
        <label htmlFor="units">
          <input
            type="number"
            id="units"
            name="units"
            value={units}
            onChange={inputChange("units")}
            placeholder="Units to sell"
            className="form-upload-select"
          />
        </label>
      )}
      <label htmlFor="price">
        <input
          type="number"
          id="price"
          name="price"
          value={price}
          onChange={inputChange("price")}
          placeholder="Price in Matric"
          className="form-upload-input"
        />
        <p style={{ textAlign: "left", opacity: "0.3", margin: "8px" }}>
          Matric = Euro
        </p>
      </label>
      <div>
        <button type="submit" className="mintbtn">
          MINT!!!!
        </button>
      </div>
    </form>
  );
};

export default FileForm;
