import React, { useState, useEffect } from "react";
import LOGOC from "../img/721_LOGO_para3D.svg"
import { Link } from '@reach/router';
import ArrowDouwn from "../img/arrow_down.svg";
import NbExperience from '../pages/nbExperience';
import { newMemberNews } from "../../services/firebase/api"
import Modal from 'react-bootstrap/Modal';
import LOGOC2 from "../img/721_logo_color.svg"


const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);


const CanvasInfo = () => {

  const [modalShow, setModalShow] = React.useState(false);
  async function addMember(data) {
    console.log(data)
    try {
      return newMemberNews(data)
    } catch (error) {
      console.log("member error", error)
    }
  }





  return (
    <div className="row logo-btn-tap-container">

      <div className="col-md-3 col-sm-2 col-xs-1 btn-tap-container"  style={{ textAlign: "center" }}>
        <button type="button" className='btn-hdm-border' variant="primary" onClick={() => setModalShow(true)}>
          Whitelist / Newsletter
        </button>
      </div>

      <div className="col-md-3 col-sm-2 col-xs-1 logo-tap-container" style={{ textAlign: "center" }}>
        <NavLink to="/">
          <img src={LOGOC} alt="Imagen PNG alternativa" className="logo-home" />
        </NavLink>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        addMember={addMember}
      />

      {/* <div className="col-md-1 col-sm-2 col-xs-1 "></div> */}

      <div className="col-md-3 col-sm-2 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
        <NavLink className="" to="/nb-experience" style={{ marginTop: "1.5", marginBottom: "1rem", textDecoration: "none", display: "none" }}>
          <button className="btn-experience2 ">Microverse Experience</button>
        </NavLink>
      </div>

      {/* <div className="col-md-1 col-sm-2 col-xs-1 "></div> */}
    </div>

  );
};

function MyVerticallyCenteredModal(props) {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    comment: ""
  };

  const [values, setValues] = useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  const memberAdd = async (data) => {
    await props.addMember(data);
    console.log(data)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    memberAdd(values);
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={(e) => handleSubmit(e)} method="POST" >
        <Modal.Body>
          <div className="field-shift row">
            <div className="col-md-12 col-sm-12 col-xs-1" style={{ textAlign: "center", marginTop: "1rem", marginBottom: "1rem" }}>
              <img src={LOGOC2} alt="logo" style={{ marginBottom: "1rem", width: "12%" }} />
              <h4>Wait list / Newsletter</h4>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-1" style={{ marginBottom: "1rem" }} >
              <input className="news-input" type="text" name="firstName" tabindex="-1" placeholder="First Name" id="b_name" onChange={handleInputChange} />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-1 " style={{ marginBottom: "1rem" }}>
              <input className="news-input" type="text" name="lastName" tabindex="-1" placeholder="Last Name" id="b_name" onChange={handleInputChange} />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-1 " style={{ marginBottom: "1rem" }}>
              <input className="news-input" type="email" name="email" tabindex="-1" placeholder="youremail@gmail.com" id="b_email" onChange={handleInputChange} />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-1 ">
              <textarea className="txt-area news-input" name="comment" tabindex="-1" placeholder="Please specify if you want to be part of Harddiskmuseum or just receive our Newsletter" id="b_comment" maxLength={500} onChange={handleInputChange}></textarea>
            </div>
          </div>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <button className="btn-modal-newsletter" type="button" onClick={props.onHide}>Close</button>
            <button className="btn-modal-newsletter " type="submit" onClick={props.onHide} >Send</button>
          </Modal.Footer>
        </Modal.Body>
      </form>
    </Modal>
  );
}


export default CanvasInfo;
