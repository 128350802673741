import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const SelectExperience = ({ index, avatar, banner, username, uniqueId }) => {
  return (
    <div className="itm" index={index}>
      <div className="nft_coll">
        <div className="nft_wrap">
          <span>
            <img src={banner} className="lazy img-fluid" alt="" />
          </span>
        </div>

        <div className="nft_coll_info">
          <div>
            <span onClick={() => window.open("/home", "_self")}>
              <h6>Experience: {username}</h6>
            </span>
            <span>Participants: {uniqueId}</span>
          </div>
          <div>
            <span onClick={() => window.open("", "_self")}>
              <i className="ellipse">
                <FontAwesomeIcon
                  className="ellipse1"
                  icon={faEllipsisV}
                ></FontAwesomeIcon>
              </i>
            </span>
          </div>
        </div>

        <div className="nft_coll_info2">
          <div>
            <span>Curated by: {uniqueId}</span>
          </div>
          <div>
            {" "}
            <span>1/25</span>
          </div>
        </div>
        <div className="nft_coll_info3">
          <button>Select invitation</button>
        </div>
      </div>
    </div>
  );
};

export default memo(SelectExperience);
