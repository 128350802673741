import React from 'react';

import Header from '../menu/header';
import Footer from '../components/footer';

const ParticipationGuide = () => {
    return (
        <>
            <Header />
            <div>
                <section className='row sect'>
                    <div className="col-md-12 col-sm-12 col-xs-1">
                        <h2 className="st--c-YygXh st--c-YygXh-ibiyHhy-css">Community Guidelines</h2>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <p>
                            Creating a new system of support for digital art requires mutual respect between
                            creators, collectors, and curators. So while we provide an unprecedentedly open
                            cultural community, we also want everyone to agree on a few key rules and expectations
                            to start. We hope you can embody these ideals and help us work towards building a welcoming
                            community that empowers creators and collectors alike.
                        </p>

                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h2>For the Community</h2>
                        <p>
                            To break from the old and start something truly new, we must be led by values of kindness and inclusivity. Showing respect and care towards one another is of utmost importance to all of us as we build a community that is welcoming to all.
                            Anyone who is interested in joining our community on a deeper level is welcome to become a member of the Foundation Discord. There you’ll find creators and collectors engaging in meaningful conversations about creative work and process. We also support and learn from each other in shared online spaces like Instagram and Twitter.
                            These spaces are also crucial to our belief in community-led curation. Our curatorial team selected the first artists to join the site, and now our creator community is leading the way. This approach sets the stage for a more vibrant and supportive network of creatives to emerge over time.
                        </p>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h2>For Creators</h2>
                        <p>
                            We always want to center artists and their perspectives in all that we do, which includes ensuring the work uploaded to Foundation is authentic to the creator. We take this seriously because building trust is essential to sustaining a healthy community.
                        </p>
                        <h4>When you mint and sell an NFT on Foundation:</h4>
                        <ol id="list-guide" >
                            <li type="disc">You maintain all legal rights, including copyrights and trademarks of your original work.</li>
                            <li type="disc">You can reproduce, distribute, exhibit, and make derivative work of your piece</li>
                        </ol>
                        <h4>What you can’t do as a creator: </h4>
                        <ol id="list-guide">
                            <li type="disc">You can’t upload anyone else’s copyrighted or otherwise proprietary work.</li>
                            <li type="disc">You can’t create additional NFTs that represent the same piece. However, related works in a series are acceptable. </li>
                        </ol>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h2>For Collectors</h2>
                        <p>
                            Collectors in the NFT space are ushering in new cultural
                            paradigms and models for arts patronage through their forward-thinking approaches.
                            When collectors win an auction, they receive a unique NFT from an artist they want to support and champion.
                        </p>
                        <h4>When you collect an NFT on Foundation: </h4>
                        <ol>
                            <li type="disc">You own the NFT that represents the artwork on the blockchain.</li>
                            <li type="disc">You can display and share the piece.</li>
                            <li type="disc">You can exhibit the piece on any platform or in any virtual space. </li>
                            <li type="disc">You can resell or trade it on a secondary market.</li>
                        </ol>

                        <h4>What you can’t do as a collector:</h4>
                        <ol>
                            <li type="disc">You can’t claim legal ownership, copyrights, trademarks, or other intellectual property rights.</li>
                            <li type="disc">You can’t use the artwork in a commercial context.</li>
                            <li type="disc">You can’t make any changes to the artwork. </li>
                            <li type="disc">You can’t share the work in a hateful, cruel, or intolerant context.</li>
                            <li type="disc">You can’t create additional NFTs that represent the same artwork.</li>

                        </ol>

                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-1 p-creating">
                        <h2>We all have a responsibility to create a positive environment.</h2>
                        <p>
                            The short version is: Don’t lie, spam, steal, cause harm, or be hateful. 
                            If you see any of these behaviors on Foundation, let us know via Intercom or 
                            hola@harddiskmuseum.com. If we determine a creator is doing any of these things, 
                            we will mediate the situation which may include account suspension. 
                            Full details are in our Terms of Service.

                        </p>
                    </div>


                </section>
                <Footer />
            </div>
        </>
    );
}

export default ParticipationGuide;
