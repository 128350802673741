import React, { Component } from 'react';
import Footer from '../components/footer';
import CanvasInfo2 from '../components/CanvasInfo2';
import Community from '../components/community';

import { Tabs, Tab } from "react-bootstrap";
import { Link } from '@reach/router';

import CollectiblesCollections from '../components/collectiblesCollections';
import ExperiencesList from '../components/experiencesList';

import iInstagram from "../img/iconmonstr-instagram-11.svg";
import iTwitter from "../img/iconmonstr-twitter-3.svg";
import iLink from "../img/iconmonstr-link-1.svg";

function EditButton(props) {
  return (
    <button onClick={props.onClick}>
      editar perfil
    </button>
  );
}

function CancelButton(props) {
  return (
    <button onClick={props.onClick}>
      cancelar
    </button>
  );
}

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

class UProfile extends Component {
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.state = { isEdit: false };
  }

  handleLoginClick() {
    this.setState({ isEdit: true });
  }

  handleLogoutClick() {
    this.setState({ isEdit: false });
  }

  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const isEdit = this.state.isEdit;
    let button;

    if (isEdit) {
      button = <EditButton onClick={this.handleLogoutClick} />;
    } else {
      button = <CancelButton onClick={this.handleLoginClick} />;
    }

    return (
      <div>
        <section className="jumbotron no-bg" style={{ padding: "0px" }}>
          <div className="canvas">
            <img onClick="style.pointerEvents=none" src="https://uc3cb29c7b19f85458f01510640a.previews.dropboxusercontent.com/p/thumb/ABWXSSfPRYDzR5NEGWr8ZBUyoAIJPN8wDRCNZ5dqPDzs9rHTJ72arXLpMxVgiTNRvy4KX7JugnyAoOLrC-l-bk4q6gGoGBzGnkcCaocmXy4loF74CdgZoKXcfP5jAfEAm5xLY4h84itlSXitubnrcAOcNu-1ggYSQpujdDgde5S52FBD15-DWBOE87yc22wys7z2PMIjJcO0Tw0KTB4I2NHuc0QWuKLl8C3lXqNF_onw4ipZQ9gDIXdzOLYYb_6unBkcWIXER8oRmA4TkOj2g6XPUHLt_V-vUX7u90YQH6s8w6JPOzzvHXM36g5pbVQs546uWCWaLVfASTQhUkVYfiIVWApJvhhR3MccDlheiYNvJOJTqQnPj40ck2xqEECMSqU/p.png" style={{ background: "whiteSmoke", width: "100%", height: "100%" }} />
            <CanvasInfo2 />
          </div>
        </section>

        <section className='container no-bottom user-profile-container' >
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-md-5 col-sm-6 col-xs-1" >
              <div className=" icons-img-container" style={{ marginTop: "4.5rem" }}>
                <ul className="icons-li-container" style={{ justifyContent: "right" }}>
                  <li className="icons-li">
                    <a className="a-icons-li" href="https://twitter.com/solimanlopez" target="_blank" rel="noopener noreferrer" style={{ marginRight: "0rem" }}>@artistwitter <img style={{ width: "15px", height: "15px" }} src={iTwitter} /></a>
                  </li>
                  <li className="icons-li">
                    <a className="a-icons-li" href="https://twitter.com/solimanlopez" target="_blank" rel="noopener noreferrer" style={{ marginRight: "0rem" }}>@artistinstagram <img style={{ width: "15px", height: "15px" }} src={iInstagram} /></a>
                  </li>
                  <li className="icons-li">
                    <a className="a-icons-li" href="https://twitter.com/solimanlopez" target="_blank" rel="noopener noreferrer" style={{ marginRight: "0rem" }}>www.artistweb.com <img style={{ width: "15px", height: "15px" }} src={iLink} /></a>
                  </li>
                </ul>
                <div className="img-profile-container" >
                  <img className="img-profile" src="https://www.xatobaxestion.com/wp-content/uploads/2016/09/default-user-img.jpg" alt="" />
                </div>
              </div>

              <div className="col-md-12 col-sm-6 col-xs-1 edit-btn-container" >
                {/* <div>
                    <Greeting isEdit={isEdit} />
                    {button}
                </div> */}

                {/*<NavLink to="/editProfile" id="connect" className=" btn-main " >
                  <button className="btn-experience4" style={{ marginTop: "0.3rem" }}
                    type="button" >Edit profile</button>
                </NavLink>*/}
                <div className="text-right">
                  <button className="btn-experience4" onClick={this.continue}>Edit profile</button>
                </div>
              </div>

              <div className="col-md-12 col-sm-6 col-xs-1 user-container" style={{ marginTop: "1.5rem" }}>
                <p>User stats</p>
                <hr className="hr-prof"  />
                <ul>
                  <li>22 artworks </li>
                  <li>240 visits</li>
                  <li>340 favorites</li>
                  <li>10 sales</li>
                  <li>Total incomes: 1049 MATIC</li>
                </ul>
              </div>
            </div>

            <div className="col-md-1 col-sm-6 col-xs-1"></div>

            <div className="col-md-5 col-sm-6 col-xs-1" style={{ textAlign: "left" }}>
              <h2 style={{ marginTop: "5rem" }}>Artist name</h2>
              <p>Artist or Curator or Both</p>
              <hr className="hr-effects2" style={{ height: "0.3rem" }} />
              <p>
                Bio (No more than 800 characters) Lorem Ipsum is simply dummy text of
                the printing and typesetting industry. Lorem Ipsum has been the industry’s
                standard dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently
                with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              </p>
            </div>
          </div>
        </section>

        <div className='collectible-container container no-top no-bottom'  >
          <div className='row'>
            <div className="col-md-12 col-sm-6 col-xs-1" style={{ textAlign: "center", marginTop: "2rem" }}>
              <hr className="hr-effects3" style={{ height: "0.3rem" }} />
            </div>
            <div className="spacer-double"></div>
            <div className='col-lg-12' style={{ textAlign: "center", marginBottom: "2rem" }}>
              <h2>Artworks</h2>
            </div>
          </div>

          <Tabs fill defaultActiveKey="home">
            <Tab className="tab-menu" eventKey="home" title="In Experiences">
              <hr className="vertical-line" />
              <div>
                < ExperiencesList />
              </div>
            </Tab>
            <Tab eventKey="profile" title="In collections">
              <hr className="vertical-line1" />
              <div>
                <CollectiblesCollections />
              </div>
            </Tab>
            <Tab eventKey="contact" title="Collected">
              <hr className="vertical-line2" />
              <div>
                <CollectiblesCollections />
              </div>
            </Tab>
            <Tab eventKey="Facv" title="Favorites">
              <hr className="vertical-line3" />
              <div>
                <CollectiblesCollections />
              </div>
            </Tab>
          </Tabs>
        </div>

        <section className='hdm-btns' style={{ background: "#EAEAEA", marginTop: "" }}>
          <div className="text-container-colecciones-art" >
            <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Share your profile</button>
          </div>

          <div className='row '>
            <div className="col-md-12 col-sm-6 col-xs-1 container" style={{ textAlign: "center", marginTop: "" }}>
              <hr className="hr-effects3" style={{ height: "0.3rem" }} />
            </div>

            <div className="spacer-double  container"></div>
            <div className='col-lg-12  ' style={{ textAlign: "center" }}>
              <h2>Harddiskmuseum</h2>
            </div>
            <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
              <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Become a Harddiskmuseum artist</button>
            </div>
            <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
              <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Visit the Museum</button>
            </div>
            <div className="col-md-12 col-sm-1 col-xs-1 btn-tap-container" style={{ textAlign: "center" }}>
              <button className="btn-hdm" style={{ marginTop: "0.7rem" }}>Our blog</button>
            </div>
          </div>
        </section>

        <section className="row" style={{ textAlign: "center" }}>
          <div className="col-md-12 col-sm-1 col-xs-1" >
            <Community />
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default UProfile;


