import React, { createContext } from 'react';
import { watchFiles, watchUserChanges } from '../services/firebase';

export const FilesContext = createContext();

export class FilesContextProvider extends React.Component {
    state = {
        files: [],
    }

    componentDidMount() {
        // watchUserChanges((user) => {
        //     if (user && !this.isSetup) {
        //         this.isSetup = true;
        //         //whatsFiles here
        //     }
        // })
        if (!this.isSetup) {
            this.isSetup = true;
            watchFiles((files) => {
                this.setState({ files })
            })
        }
    }

    render() {
        return (
            <FilesContext.Provider value={this.state}>
                {this.props.children}
            </FilesContext.Provider>
        )
    }
}

export const FilesContextConsumer = FilesContext.Consumer;